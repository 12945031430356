<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar common_style" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 894px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Deadlines bearbeiten</h4>
        <div class="text-muted mb12">
          Die Deadline für die Erstellung des Arbeitberichtes startet mit dem Einsatzzeitpunkt und nach dem Ablauf erfolgt eine Benachrichtigung.
        </div>
        <section>
          <h2>Deadline 1</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <mat-checkbox color="primary" formControlName="use_deadline">Deadline 1 verwenden</mat-checkbox>
            </div>
            <div class="input_row">
              <div class="input_label">
                Deadline
              </div>
              <div class="input_container flex_row">
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_days">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      Tage
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_hours">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      h
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_minutes">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Per
              </div>
              <div class="input_container">
                <div class="flex_col">
                  <mat-checkbox color="primary" formControlName="mail">Mail</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="sms">Sms</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Deadline 2</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <mat-checkbox color="primary" formControlName="use_deadline2">Deadline 2 verwenden</mat-checkbox>
            </div>
            <div class="input_row">
              <div class="input_label">
                Deadline
              </div>
              <div class="input_container flex_row">
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_days2">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      Tage
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_hours2">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      h
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_minutes2">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Per
              </div>
              <div class="input_container">
                <div class="flex_col">
                  <mat-checkbox color="primary" formControlName="mail2">Mail</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="sms2">Sms</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Deadline 3</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <mat-checkbox color="primary" formControlName="use_deadline3">Deadline 3 verwenden</mat-checkbox>
            </div>
            <div class="input_row">
              <div class="input_label">
                Deadline
              </div>
              <div class="input_container flex_row">
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_days3">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      Tage
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_hours3">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      h
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_minutes3">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Per
              </div>
              <div class="input_container">
                <div class="flex_col">
                  <mat-checkbox color="primary" formControlName="mail3">Mail</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="sms3">Sms</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>