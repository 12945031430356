import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormModalComponent } from "../form-modal-component.class";
import { FullProjectHash } from "../../../../_models/project.interface";
import { MailTemplate } from "../../../../_models/mail-template.interface";
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatFormField, MatHint } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { NgIf } from "@angular/common";
import { MatOption, MatSelect } from "@angular/material/select";

@Component({
  selector: 'hawk-add-new-mail-template-modal',
  imports: [
    FormsModule,
    MatButton,
    MatCheckbox,
    MatFormField,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    MatSelect,
    MatHint,
    MatOption
  ],
  templateUrl: './add-new-mail-template-modal.component.html',
  standalone: true,
  styleUrls: ['add-new-mail-template-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class AddNewMailTemplateModalComponent extends FormModalComponent<MailTemplate, FullProjectHash> {

  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash>= new EventEmitter<FullProjectHash>();

  override formGroupToDBInput(): MailTemplate {
      throw new Error('Method not implemented.');
  }

  override getDBCallObservable(object: MailTemplate): Observable<FullProjectHash> {
      throw new Error('Method not implemented.');
  }

}
