import { EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";

export abstract class FormModalComponent<DBInput, DBOutput> {

  abstract formGroup: UntypedFormGroup;
  abstract closeModal: EventEmitter<void>
  abstract newDBOutput: EventEmitter<DBOutput>

  save(): void {
    if (this.formGroup.valid) {
      this.saveToDatabase(this.formGroupToDBInput()).then(
        success => {
          if (success) {
            this.newDBOutput.emit(success)
            this.closeModal.emit()
          }
        }
      );
    }
  }

  subscribeToDBCall(observable: Observable<DBOutput>): Promise<DBOutput | false> {
    return new Promise((resolve) => {
      observable.subscribe(
        data => {
          resolve(data)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })
  }

  async saveToDatabase(db_input: DBInput): Promise<DBOutput | false> {
    return await this.subscribeToDBCall(this.getDBCallObservable(db_input))
  }

  abstract formGroupToDBInput(): DBInput;
  abstract getDBCallObservable(object: DBInput): Observable<DBOutput>
}