import { Routes } from '@angular/router'
import { WarehouseInboundComponent } from './warehouse-inbound/warehouse-inbound.component'
import { WarehouseIndexComponent } from './warehouse-index/warehouse-index.component'
import { LoginComponent } from './login/login.component'
import { authGuard } from './_guards'
import { WelcomeComponent } from './welcome/welcome.component'
import { WarehouseBulkListComponent } from './warehouse-bulk-list/warehouse-bulk-list.component'
import { TourPlannerComponent } from './tour-planner/tour-planner.component'
import { TicketReviewComponent } from './_components/ticket-review/ticket-review.component'
import { TicketPageComponent } from './_components/ticket-page/ticket-page.component'
import { TicketFixerComponent } from './ticket-fixer/ticket-fixer.component'
import { TicketShipmentImportComponent } from './ticket-shipment-import/ticket-shipment-import.component'
import { QmPageComponent } from './_components/qm-page/qm-page.component'
import { ArticleSplitterPageComponent } from './_components/article-splitter-page/article-splitter-page.component'
import { WarehouseRetoureComponent } from './warehouse-retoure/warehouse-retoure.component'
import { ArticleSearchComponent } from './_components/article-search/article-search.component'
import { WarehouseMainComponent } from './warehouse-main/warehouse-main.component'
import { WarehouseShowComponent } from './warehouse-show/warehouse-show.component'
import { WarehouseArticlesComponent } from './warehouse-articles/warehouse-articles.component'
import { DeliveryNoteShowComponent } from './_components/delivery-note-show/delivery-note-show.component'
import { DeliveryNoteCreateComponent } from './_components/delivery-note-create/delivery-note-create.component'
import { DeliveryNoteShippingComponent } from './_components/delivery-note-shipping/delivery-note-shipping.component'
import { RetoureShowComponent } from './_components/retoure-show/retoure-show.component'
import { ShipCloudRetoureComponent } from './_components/ship-cloud-retoure/ship-cloud-retoure.component'
import { DeliveryNoteReceiptComponent } from './_components/delivery-note-receipt/delivery-note-receipt.component'
import { DeliveryNotePickupComponent } from './_components/delivery-note-pickup/delivery-note-pickup.component'
import { ArticleSearchFormComponent } from './_components/article-search-form/article-search-form.component'
import { ArtikelSearchComponent } from './_components/artikel-search/artikel-search.component'
import { ArticleFormComponent } from './_components/article-form/article-form.component'
import { ArticleShowComponent } from './_components/article-show/article-show.component'
import { ArticleTemplatesComponent } from './_components/article-templates/article-templates.component'
import { ArticleTemplateShowComponent } from './_components/article-template-show/article-template-show.component'
import { TicketFormComponent } from './_components/ticket-form/ticket-form.component'
import { TicketSearchComponent } from './_components/ticket-search/ticket-search.component'
import { TicketSearchFormComponent } from './_components/ticket-search-form/ticket-search-form.component'
import { DeliveryNoteSearchFormComponent } from './_components/delivery-note-search-form/delivery-note-search-form.component'
import { DeliveryNoteSearchComponent } from './_components/delivery-note-search/delivery-note-search.component'
import { PlanningAssistantComponent } from './_components/planning-assistant/planning-assistant.component'
import { ProjectTempComponent } from './_components/project-temp/project-temp.component'
import { UserIndexComponent } from "./_components/user-index/user-index.component";
import { UserCreateComponent } from "./_components/user-create/user-create.component";
import { UserShowComponent } from "./_components/user-show/user-show.component";
import { UserEditComponent } from "./_components/user-show/user-edit/user-edit.component";
import {
  UserChangePasswordComponent
} from "./_components/user-show/user-change-password/user-change-password.component";
import { StoreShowComponent } from "./_components/store-show/store-show.component";
import { StoreCreateComponent } from "./_components/store-create/store-create.component";
import { StoreIndexComponent } from "./_components/store-index/store-index.component";
import { CustomerIndexComponent } from "./_components/customer-index/customer-index.component";
import { CustomerCreateComponent } from "./_components/customer-create/customer-create.component";
import { CustomerShowComponent } from "./_components/customer-show/customer-show.component";
import { CustomerImportStoreComponent } from "./_components/customer-import-store/customer-import-store.component";
import { ProjectIndexComponent } from "./_components/project-index/project-index.component";
import { ProjectShowComponent } from "./_components/project-show/project-show.component";
import { ProjectCreateComponent } from "./_components/project-create/project-create.component";

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: WelcomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'lager',
    canActivate: [authGuard],
    component: WarehouseIndexComponent,
  },
  {
    path: 'lager/uebersicht',
    canActivate: [authGuard],
    component: WarehouseMainComponent,
  },
  {
    path: 'lager/eingang',
    canActivate: [authGuard],
    component: WarehouseInboundComponent,
  },
  {
    path: 'lager/verarbeitung',
    canActivate: [authGuard],
    component: WarehouseBulkListComponent,
  },
  {
    path: 'lager/artikel',
    canActivate: [authGuard],
    component: ArticleSearchComponent,
  },
  {
    path: 'lager/artikel_aufteilen',
    canActivate: [authGuard],
    component: ArticleSplitterPageComponent,
  },
  {
    path: 'lager/:id/retoure_international',
    canActivate: [authGuard],
    component: WarehouseRetoureComponent,
  },
  {
    path: 'lager/retoure',
    canActivate: [authGuard],
    component: WarehouseRetoureComponent,
  },
  {
    path: 'lager/:id',
    canActivate: [authGuard],
    component: WarehouseShowComponent,
  },
  {
    path: 'lager/:id/lagerort/:storage_bin_id/:article_type',
    canActivate: [authGuard],
    component: WarehouseArticlesComponent
  },
  {
    path: 'lager/:id/:article_type',
    canActivate: [authGuard],
    component: WarehouseArticlesComponent
  },
  {
    path: 'routenplaner',
    canActivate: [authGuard],
    component: TourPlannerComponent,
  },
  {
    path: 'ticket/fixer',
    canActivate: [authGuard],
    component: TicketFixerComponent,
  },
  {
    path: 'ticket/shipment/import',
    canActivate: [authGuard],
    component: TicketShipmentImportComponent,
  },
  {
    path: 'ticket/suche',
    canActivate: [authGuard],
    component: TicketSearchComponent,
  },
  {
    path: 'ticket/suche/index',
    canActivate: [authGuard],
    component: TicketSearchFormComponent,
  },
  {
    path: 'lieferscheine/suche',
    component: DeliveryNoteSearchComponent,
  },
  {
    path: 'lieferscheine/suche/index',
    component: DeliveryNoteSearchFormComponent,
  },
  {
    path: 'artikel/suche/index',
    canActivate: [authGuard],
    component: ArticleSearchFormComponent
  },
  {
    path: 'artikel/suche',
    canActivate: [authGuard],
    component: ArtikelSearchComponent
  },
  {
    path: 'artikel/neu',
    canActivate: [authGuard],
    component: ArticleFormComponent,
    pathMatch: 'full'
  },
  {
    path: 'artikel/neu/:old_article_id',
    canActivate: [authGuard],
    component: ArticleFormComponent,
    pathMatch: 'full'
  },
  {
    path: 'artikel/:id/edit',
    canActivate: [authGuard],
    component: ArticleFormComponent
  },
  {
    path: 'artikel/:id',
    canActivate: [authGuard],
    component: ArticleShowComponent
  },
  {
    path: 'artikelvorlagen/uebersicht',
    canActivate: [authGuard],
    component: ArticleTemplatesComponent
  },
  {
    path: 'artikelvorlagen/:id',
    canActivate: [authGuard],
    component: ArticleTemplateShowComponent
  },
  {
    path: 'artikelvorlagen/neu',
    canActivate: [authGuard],
    component: ArticleTemplateShowComponent
  },
  {
    path: 'lieferscheine/neu',
    canActivate: [authGuard],
    component: DeliveryNoteCreateComponent
  },
  {
    path: 'lieferscheine/:id/edit',
    canActivate: [authGuard],
    component: DeliveryNoteCreateComponent
  },
  {
    path: 'lieferscheine/:id',
    canActivate: [authGuard],
    component: DeliveryNoteShowComponent
  },
  {
    path: 'lieferscheine/:id/shipping',
    canActivate: [authGuard],
    component: DeliveryNoteShippingComponent
  },
  {
    path: 'lieferscheine/:id/shipping-retoure',
    canActivate: [authGuard],
    component: ShipCloudRetoureComponent
  },
  {
    path: 'lieferscheine/:id/pickup',
    canActivate: [authGuard],
    component: DeliveryNotePickupComponent
  },
  {
    path: 'lieferscheine/:id/quittieren',
    canActivate: [authGuard],
    component: DeliveryNoteReceiptComponent
  },
  {
    path: 'ticket/neu',
    canActivate: [authGuard],
    component: TicketFormComponent,
  },
  {
    path: 'ticket/:id',
    canActivate: [authGuard],
    component: TicketPageComponent,
  },
  {
    path: 'retoure/:id',
    canActivate: [authGuard],
    component: RetoureShowComponent
  },
  /*   {
      path: 'planning_assistant',
      canActivate: [authGuard],
      component: PlanningAssistantComponent,
    }, */
  {
    path: 'qm/uebersicht',
    canActivate: [authGuard],
    component: QmPageComponent,
  },
  {
    path: 'ticket/:id/review',
    canActivate: [authGuard],
    component: TicketReviewComponent,
  },
  {
    path: 'planning_assistant',
    canActivate: [authGuard],
    component: PlanningAssistantComponent,
  },
  {
    path: 'benutzer',
    canActivate: [authGuard],
    component: UserIndexComponent,
  },
  {
    path: 'benutzer/neu',
    canActivate: [authGuard],
    component: UserCreateComponent,
  },
  {
    path: 'benutzer/:id',
    canActivate: [authGuard],
    component: UserShowComponent,
  },
  {
    path: 'benutzer/:id/bearbeiten',
    canActivate: [authGuard],
    component: UserEditComponent,
  },
  {
    path: 'benutzer/:id/passwort',
    canActivate: [authGuard],
    component: UserChangePasswordComponent,
  },
  {
    path: 'filiale',
    canActivate: [authGuard],
    component: StoreIndexComponent,
  },
  {
    path: 'filiale/neu',
    canActivate: [authGuard],
    component: StoreCreateComponent,
  },
  {
    path: 'filiale/:id',
    canActivate: [authGuard],
    component: StoreShowComponent,
  },
  {
    path: 'kunde',
    canActivate: [authGuard],
    component: CustomerIndexComponent,
  },
  {
    path: 'kunde/neu',
    canActivate: [authGuard],
    component: CustomerCreateComponent,
  },
  {
    path: 'kunde/:id',
    canActivate: [authGuard],
    component: CustomerShowComponent,
  },
  {
    path: 'kunde/:id/filialen_import',
    canActivate: [authGuard],
    component: CustomerImportStoreComponent,
  },
  {
    path: 'project-temp/:id',
    canActivate: [authGuard],
    component: ProjectTempComponent,
  },
  {
    path: 'projekt',
    canActivate: [authGuard],
    component: ProjectIndexComponent,
  },
  {
    path: 'projekt/neu',
    canActivate: [authGuard],
    component: ProjectCreateComponent,
  },
  {
    path: 'projekt/:id',
    canActivate: [authGuard],
    component: ProjectShowComponent,
  }
  // // otherwise redirect to home
  // { path: '**', redirectTo: '' },
]