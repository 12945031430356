<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 894px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Aufgabe erstellen</h4>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              Name*
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Verantwortliche
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-label>Wählen Sie einen oder mehrere Techniker aus...</mat-label>
                <mat-select formControlName="user_ids" multiple>
                  <mat-option>Test</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="use_deadline">Deadline verwenden</mat-checkbox>
            </div>
          </div>
          <ng-container *ngIf="formGroup.controls['use_deadline'].value">
            <div class="input_row">
              <div class="input_label">
                Deadline
              </div>
              <div class="input_container flex_row">
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_days">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      Tage
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper mr24">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_hours">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      h
                    </div>
                  </div>
                </div>
                <div class="icon_wrapper">
                  <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                    <input type="number" min="0" matInput formControlName="deadline_minutes">
                  </mat-form-field>
                  <div class="icon_container" style="padding: 0 12px">
                    <div>
                      min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                An
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <mat-label>Wählen Sie einen oder mehrere Nutzer aus...</mat-label>
                  <mat-select formControlName="deadline_user_ids" multiple>
                    <mat-option>Test</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Per
              </div>
              <div class="input_container">
                <div class="flex_col">
                  <mat-checkbox color="primary" formControlName="mail">Mail</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="sms">Sms</mat-checkbox>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>