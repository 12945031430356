import { Component, inject, OnInit } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";
import { EditAddressComponent } from "../_shared/edit-address/edit-address.component";
import { EditInputComponent } from "../_shared/edit-input/edit-input.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { DatePipe, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { FullProjectHash, ProjectDeadlines, ProjectUpdateParams } from "../../_models/project.interface";
import { CustomerService, ProjectService, UserService } from "../../_services";
import {
  faArrowLeft,
  faCheck,
  faCheckCircle,
  faIndustry,
  faInfoCircle,
  faTrash, faUpload,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { MatTooltip } from "@angular/material/tooltip";
import { Customer, Technician } from "../../_models";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import {
  Column,
  GridApi, IRowNode,
  RowClassRules, SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { APTicket } from "../../_models/ticket.interface";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import {
  ProjectEditTicketAttributesComponent
} from "./project-edit-ticket-attributes/project-edit-ticket-attributes.component";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { MailTemplate } from "../../_models/mail-template.interface";
import {
  AddNewMailTemplateModalComponent
} from "./modal-components/add-new-mail-template-modal/add-new-mail-template-modal.component";
import { EditMailTemplateComponent } from "./modal-components/edit-mail-template/edit-mail-template.component";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import {
  EditProjectDeadlinesModalComponent
} from "./modal-components/edit-project-deadlines-modal/edit-project-deadlines-modal.component";
import { MatProgressBar, MatProgressBarModule } from "@angular/material/progress-bar";
import { FullProjectTechnician } from "../../_models/project-technician.interface";
import {
  EditProjectTechnicianModalComponent
} from "./modal-components/edit-project-technician-modal/edit-project-technician-modal.component";
import { PATimeControl } from "../planning-assistant/singletons/pa-time-control";
import {
  CheckboxInputType,
  AgGridFormCellComponent,
  FormGroupContext, InputType, SelectInputType
} from "../_shared/ag-grid-renderer/ag-grid-form-cell.component";
import { APStore } from "../../_models/store.interface";

export interface ProjectTechnicianFormCellInput {
  selected_input: CheckboxInputType,
  name: string,
  bin_input: SelectInputType,
  user_id: number
}

export interface ProjectStoreFormCellInput {
  selected_input: CheckboxInputType,
  store: APStore
}

@Component({
  selector: 'hawk-project-show',
  imports: [
    AgGridAngular,
    EditAddressComponent,
    EditInputComponent,
    FaIconComponent,
    FormsModule,
    MatButton,
    MatCheckbox,
    MatFormField,
    MatInput,
    MatLabel,
    NgForOf,
    NgIf,
    NgxMapboxGLModule,
    RecordNavigationComponent,
    RouterLink,
    MatTooltip,
    MatOption,
    MatSelect,
    ProjectEditTicketAttributesComponent,
    AddNewMailTemplateModalComponent,
    EditMailTemplateComponent,
    TooltipModule,
    EditProjectDeadlinesModalComponent,
    MatProgressBar,
    MatProgressBarModule,
    EditProjectTechnicianModalComponent,
    DatePipe,
    NgOptimizedImage,
    ReactiveFormsModule
  ],
  templateUrl: './project-show.component.html',
  standalone: true,
  styleUrls: ['./project-show.component.scss', '../_shared/styles/common-styles.scss']
})
export class ProjectShowComponent implements OnInit {

  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faTrash = faTrash;
  protected readonly faCheck = faCheck;

  project: FullProjectHash;
  startDate: Date
  endDate: Date
  projectIsDeletable: boolean = false;

  customers: Customer[] = [];

  tickets: APTicket[] = [];
  openTicketCount: number = 0;
  closedTicketCount: number = 0;
  statusCounts: { name: string, id: number, count: number, closed?: boolean }[] = []
  priorityCounts: { name: string, id: number, count: number }[] = []
  taskCounts: { name: string, id: number, count: number }[] = []
  totalTaskCount: number = 0

  dispatchers: Technician[] = [];
  selectedDispatcherIds: number[] = []
  technicians: Technician[] = [];
  selectedTechnicianIds: number[] = []

  projectTechnicianFormCellInputs: ProjectTechnicianFormCellInput[] = []
  projectStoreFormCellInputs: ProjectStoreFormCellInput[] = []

  // Project type
  projectBusinessType = {
    id: 1,
    name: 'Projektgeschäft'
  }
  maintenanceType = {
    id: 2,
    name: 'Wartung'
  }
  selectedProjectTypeID?: number = null
  projectTypeTranslations = {
    'PROJECT_BUSINESS': this.projectBusinessType,
    'MAINTENANCE': this.maintenanceType
  }

  selectableProjectTypes = [
    this.projectBusinessType,
    this.maintenanceType,
  ]

  editName: boolean = false;
  editActive: boolean = false;
  editCustomer: boolean = false;
  editType: boolean = false;
  editDescription: boolean = false;
  editCostCenter: boolean = false;
  editCutOffTime: boolean = false;
  editStores: boolean = false;
  editDispatchers: boolean = false;
  editTelephoneDispatching: boolean = false;
  editContactData: boolean = false;
  editContactMail: boolean = false;
  editContact: boolean = false;
  editEmail: boolean = false;
  editTicketAttributes: boolean = false;
  editTechnicians: boolean = false;
  editProductSelection: boolean = false;
  editSendOperationNew: boolean = false;
  editSendOperationUpdate: boolean = false;
  editSendOperationDelete: boolean = false;
  editStart: boolean = false;
  editEnd: boolean = false;
  editDocumentation: boolean = false;

  addMailTemplateForm: UntypedFormGroup
  editMailTemplateForm: UntypedFormGroup
  editDeadlineTemplate: UntypedFormGroup
  editProjectTechnicianForm: UntypedFormGroup

  // ag grid
  public hawkTheme = inject(AgGridThemeService).getTheme();
  public oldTheme = inject(AgGridThemeService).getOldTheme();
  protected readonly locale = AG_GRID_LOCALE_DE;
  private ticketGridApi!: GridApi;
  private projectTechnicianGridApi!: GridApi;
  private projectStoreGridApi!: GridApi;

  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public ticketColDefs: ColDef[] = [
    {
      field: "order_nr",
      headerName: "Nr.",
      width: 160,
      cellClass: 'hover_link_cell',
      cellRenderer: this.orderNrRenderer,
    },
    {
      field: "priority.name",
      headerName: "Priorität",
      width: 240,
    },
    {
      field: "store",
      headerName: "Filiale",
      width: 360,
      cellClass: 'hover_link_cell',
      cellRenderer: this.storeRenderer,
    },
  ]

  public projectTechnicianColDefs: ColDef[] = [
    {headerName: 'Aktiviert', field: "selected_input", cellRenderer: 'checkboxFormCell', width: 130, suppressSizeToFit: true},
    {headerName: 'Techniker', field: "name"},
    {headerName: 'Lagerplatz', field: "bin_input", cellRenderer: 'selectFormCell'},
  ];

  public projectStoreColDefs: ColDef[] = [
    {headerName: 'Verknüpft', field: "selected_input", cellRenderer: 'checkboxFormCell', width: 140, suppressSizeToFit: true},
    {headerName: 'Nr', field: "store.nr", width: 160},
    {headerName: 'Name', field: "store.name", width: 160},
    {headerName: 'Adresse', field: "store.address", width: 320},
  ];

  projectTechnicianForm = new UntypedFormGroup({})
  projectStoreForm = new UntypedFormGroup({})

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"
  public selectedTab: string = 'tickets';
  private customerStores: APStore[] = [];

  constructor(
    private projectService: ProjectService,
    private customerService: CustomerService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.loadCustomers()
    this.loadDispatcher()
    this.loadTechnicians()
    this.loadTickets()
  }

  loadCustomers(): void {
    this.customerService.loadCustomers().subscribe(
      customers => {
        this.customers = customers.sort((c1, c2) => c1.name.toLowerCase() < c2.name.toLowerCase() ? -1 : 1)
      },
      err => {
        console.log(err)
      }
    )
  }

  loadProject(): void {
    const id = this.route.snapshot.paramMap.get("id")
    this.projectService.getFullProject(id).subscribe(
      data => {
        this.updateProjectData(data)
      },
      error => {
        console.log(error)
      }
    )
  }

  loadDispatcher(): void {
    this.userService.getDispatcher().subscribe(
      data => {
        this.dispatchers = data
      },
      error => {
        console.log(error)
      }
    )
  }

  loadTechnicians(): void {
    this.userService.getTechnicians().subscribe(
      data => {
        this.technicians = data
        this.loadProject()
      },
      error => {
        console.log(error)
      }
    )
  }

  loadTickets(): void {
    const id = this.route.snapshot.paramMap.get("id")
    this.projectService.getTickets(id).subscribe(
      data => {
        this.tickets = data
        this.openTicketCount = data.filter(t => !t.status.closed).length
        this.closedTicketCount = data.filter(t => t.status.closed).length
        this.updateTicketCounts()
      },
      error => {
        console.log(error)
      }
    )
  }


  loadCustomerStores(): void {
    this.customerService.loadAPCustomerStores(this.project.customer.id).subscribe(
      data => {
        this.customerStores = data
        this.generateStoreFormCellInputs()
      },
      err => console.log(err)
    )
  }

  updateTicketCounts(): void {
    this.statusCounts = this.countNames(this.tickets.map(t => { return { name: t.status.name, id: t.status.id, closed: t.status.closed } }))
    this.priorityCounts = this.countNames(this.tickets.map(t => { return { name: t.priority.name, id: t.priority.id } }))
    this.taskCounts = this.countNames(this.tickets.map(t => { return t.tasks }).flat())
    this.totalTaskCount = this.taskCounts.reduce((total: number, task_count) => total + task_count.count, 0)
  }

  countNames(count_list: { name:string, id: number, closed?: boolean }[]): { name: string, id: number, count: number, closed?: boolean }[] {

    let counts: { name: string, id: number, count: number, closed?: boolean }[] = []

    for (let item of count_list) {
      let count = counts.find(c => c.id == item.id)
      if (!count) {
        counts.push({
          name: item.name,
          id: item.id,
          count: 1,
          closed: item.closed
        })
      } else {
        count.count += 1
      }
    }

    return counts.sort((ca, cb) => ca.count < cb.count ? 1 : -1)
  }

  updateProjectData(project: FullProjectHash) {
    this.project = project
    this.loadCustomerStores()
    this.selectedProjectTypeID = this.projectTypeTranslations[project.type].id
    this.selectedTechnicianIds = project.project_technicians.map(t => t.user_id)
    this.selectedDispatcherIds = project.dispatchers.map(t => t.id)
    this.startDate = this.getStartDate()
    this.endDate = this.getEndDate()
    this.generateProjectTechnicianFormCellInputs()
  }

  saveName($event: string) {
    this.saveProjectValues({name: $event}).then(success => {
      if (success) this.editName = false
    })
  }

  saveActive($event: boolean) {
    this.saveProjectValues({active: $event}).then(success => {
      if (success) this.editActive = false
    })
  }

  saveDescription($event: string) {
    this.saveProjectValues({description: $event}).then(success => {
      if (success) this.editDescription = false
    })
  }

  saveCustomer($event: number) {
    this.saveProjectValues({customer_id: $event}).then(success => {
      if (success) this.editCustomer = false
    })
  }

  saveType($event: number) {
    const types = [
      'PROJECT_BUSINESS',
      'MAINTENANCE'
    ]
    this.saveProjectValues({type: types[$event - 1]}).then(success => {
      if (success) this.editType = false
    })
  }

  saveCostCenter($event: string) {
    this.saveProjectValues({cost_center: $event}).then(success => {
      if (success) this.editCostCenter = false
    })
  }

  saveCutOffTime($event: string) {
    this.saveProjectValues({cut_off_time: $event}).then(success => {
      if (success) this.editCutOffTime = false
    })
  }

  saveContact($event: string) {
    this.saveProjectValues({contact: $event}).then(success => {
      if (success) this.editContact = false
    })
  }

  saveEmail($event: string) {
    this.saveProjectValues({email: $event}).then(success => {
      if (success) this.editEmail = false
    })
  }

  saveProductSelection($event: boolean) {
    this.saveProjectValues({select_products: $event}).then(success => {
      if (success) this.editProductSelection = false
    })
  }

  saveSendOperationNew($event: boolean) {
    this.saveProjectValues({send_operation_new: $event}).then(success => {
      if (success) this.editSendOperationNew = false
    })
  }

  saveSendOperationUpdate($event: boolean) {
    this.saveProjectValues({send_operation_update: $event}).then(success => {
      if (success) this.editSendOperationUpdate = false
    })
  }

  saveSendOperationDelete($event: boolean) {
    this.saveProjectValues({send_operation_delete: $event}).then(success => {
      if (success) this.editSendOperationDelete = false
    })
  }

  async saveTechnicians(ids: number[]) {
    const add_technician_ids = ids.filter(id => !this.project.project_technicians.find(t => t.user_id == id))
    const remove_technician_ids = this.project.project_technicians.map(t => t.user_id).filter(pt_id => !ids.find(id => pt_id == id))

    await new Promise<boolean>(resolve => {
      this.projectService.updateProjectTechnicians(this.project.id, {remove_technician_ids, add_technician_ids}).subscribe(
        data => {
          this.updateProjectData(data)
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    }).then(success => {
      if (success) this.editTechnicians = false
    })
  }

  async saveDispatchers(ids: number[]) {
    const add_dispatcher_ids = ids.filter(id => !this.project.dispatchers.find(t => t.id == id))
    const remove_dispatcher_ids = this.project.dispatchers.map(t => t.id).filter(pt_id => !ids.find(id => pt_id == id))

    await new Promise<boolean>(resolve => {
      this.projectService.updateProjectDispatchers(this.project.id, {remove_dispatcher_ids, add_dispatcher_ids}).subscribe(
        data => {
          this.updateProjectData(data)
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    }).then(success => {
      if (success) this.editDispatchers = false
    })
  }

  saveStartDate($event: Date) {
    console.log('saving: ' + $event.toString())
    const date = $event ? PATimeControl.Instance.formatDate($event) : null
    this.saveProjectValues({start: date}).then(success => {
      if (success) this.editStart = false
    })
  }

  saveEndDate($event: Date) {
    const date = $event ? PATimeControl.Instance.formatDate($event) : null
    this.saveProjectValues({end: date}).then(success => {
      if (success) this.editEnd = false
    })
  }

  saveDocumentation($event: string) {
    this.saveProjectValues({additional_documentation: $event}).then(success => {
      if (success) this.editDocumentation = false
    })
  }

  async saveProjectValues(params: ProjectUpdateParams): Promise<boolean> {
    return await new Promise<boolean>(resolve => {
      this.projectService.updateFullProject(this.project.id, params).subscribe(
        data => {
          this.updateProjectData(data)
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })
  }

  deleteProject() {

  }


  onTicketGridReady(params: GridReadyEvent) {
    this.ticketGridApi = params.api
  }

  onProjectTechnicianGridReady(params: GridReadyEvent): void {
    this.projectTechnicianGridApi = params.api
    this.initProjectTechnicianFormControls()
  }

  onProjectStoreGridReady(params: GridReadyEvent): void {
    this.projectStoreGridApi = params.api
    this.initProjectStoreFormControls()
  }

  public ticketQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.ticketGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public projectTechnicianQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.projectTechnicianGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public storeQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.projectStoreGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  orderNrRenderer(params: any): string {
    const id = params.data.id
    return `<a class="hover_link" href="/a/ticket/${id}" rel="noopener">${params.value}</a>`
  }

  storeRenderer(params: any): string {
    if (params.value) {
      const id = params.value.id
      return `<a class="hover_link" href="/a/filiale/${id}" rel="noopener">${params.value.name}</a>`
    } else {
      return '-'
    }
  }

  customerRenderer(params: any): string {
    const id = params.value.id
    return `<a class="hover_link" href="/a/kunde/${id}" rel="noopener">${params.value.name}</a>`
  }

  openAddMailTemplateForm(): void {
    this.addMailTemplateForm = this.getMailTemplateForm()
  }

  openEditMailTemplateForm(mail_template: MailTemplate): void {
    this.editMailTemplateForm = this.getMailTemplateForm(mail_template)
  }

  getMailTemplateForm(template?: MailTemplate): UntypedFormGroup {
    let form = new UntypedFormGroup({
      "id": new UntypedFormControl({value: '', disabled: true}),
      "subject": new UntypedFormControl({value: ''}, Validators.required),
      "text": new UntypedFormControl({value: ''}, Validators.required),
      "type": new UntypedFormControl({value: 'STATUS_CHANGED'}, Validators.required),
      "project_id": new UntypedFormControl({value: this.project.id}, Validators.required)
    })

    form.controls['id'].setValue(template?.id || null)
    form.controls['subject'].setValue(template?.subject || '')
    form.controls['text'].setValue(template?.text || '')
    form.controls['type'].setValue(template ? template.type : 'STATUS_CHANGED')
    form.controls['project_id'].setValue(this.project.id)

    for (let key of [
      'orderNr','status','reportLink','dateSLA','operationDate','tasks','priority','dateCreated','externalOrderNr','description',
      'project','store','address','deliveryNotes','contactName','contactEmail','contactPhone','combined_report','operation.date','operation.status',
      'operation.technicians','operation.description','operation.report','operation.travelTimeStart','operation.travelTimeEnd','operation.travelTime',
      'operation.workingTimeStart','operation.workingTimeEnd','operation.workingTime','operation.dateTravelStart','operation.dateOnSite',
      'operation.dateRepaired','operation.dateFinished','operation.distance','operation.files','operation.add_report_photo','operation.dont_send_report'
    ]) {
      form.addControl(key, new UntypedFormControl({value: false}))
      form.controls[key].setValue(template?.mail_fields ? !!template?.mail_fields.find(field => field.name == key) : false)
    }

    form.updateValueAndValidity()

    return form
  }

  openEditDeadlineModal() {
    this.editDeadlineTemplate = this.getDeadlineForm(this.project)
  }

  getDeadlineForm(template?: ProjectDeadlines): UntypedFormGroup {
    let form = new UntypedFormGroup({
      "use_deadline": new UntypedFormControl({value: ''}),
      "use_deadline2": new UntypedFormControl({value: ''}),
      "use_deadline3": new UntypedFormControl({value: ''}),
      "deadline_days": new UntypedFormControl({value: ''}),
      "deadline_days2": new UntypedFormControl({value: ''}),
      "deadline_days3": new UntypedFormControl({value: ''}),
      "deadline_hours": new UntypedFormControl({value: ''}),
      "deadline_hours2": new UntypedFormControl({value: ''}),
      "deadline_hours3": new UntypedFormControl({value: ''}),
      "deadline_minutes": new UntypedFormControl({value: ''}),
      "deadline_minutes2": new UntypedFormControl({value: ''}),
      "deadline_minutes3": new UntypedFormControl({value: ''}),
      "mail": new UntypedFormControl({value: ''}),
      "mail2": new UntypedFormControl({value: ''}),
      "mail3": new UntypedFormControl({value: ''}),
      "sms": new UntypedFormControl({value: ''}),
      "sms2": new UntypedFormControl({value: ''}),
      "sms3": new UntypedFormControl({value: ''}),
    })

    form.controls['use_deadline'].setValue(template?.use_deadline || false)
    form.controls['use_deadline2'].setValue(template?.use_deadline2 || false)
    form.controls['use_deadline3'].setValue(template?.use_deadline3 || false)
    form.controls['deadline_days'].setValue(template?.deadline_days || 0)
    form.controls['deadline_days2'].setValue(template?.deadline_days2 || 0)
    form.controls['deadline_days3'].setValue(template?.deadline_days3 || 0)
    form.controls['deadline_hours'].setValue(template?.use_deadline || 0)
    form.controls['deadline_hours2'].setValue(template?.deadline_hours2 || 0)
    form.controls['deadline_hours3'].setValue(template?.deadline_hours3 || 0)
    form.controls['deadline_minutes'].setValue(template?.deadline_minutes || 0)
    form.controls['deadline_minutes2'].setValue(template?.deadline_minutes2 || 0)
    form.controls['deadline_minutes3'].setValue(template?.deadline_minutes3 || 0)
    form.controls['mail'].setValue(template?.mail || false)
    form.controls['mail2'].setValue(template?.mail2 || false)
    form.controls['mail3'].setValue(template?.mail3 || false)
    form.controls['sms'].setValue(template?.sms || false)
    form.controls['sms2'].setValue(template?.sms2 || false)
    form.controls['sms3'].setValue(template?.sms3 || false)

    form.updateValueAndValidity()

    return form
  }

  openEditProjectTechnicianForm(project_technician: FullProjectTechnician): void {
    this.editProjectTechnicianForm = this.getProjectTechnicianForm(project_technician)
  }

  getProjectTechnicianForm(template: FullProjectTechnician): UntypedFormGroup {
    let form = new UntypedFormGroup({
      "id": new UntypedFormControl({value: '', disabled: true}),
      "user_id": new UntypedFormControl({value: '', disabled: true}),
      "zip_area": new UntypedFormControl({value: ''}),
      "standby_time": new UntypedFormControl({value: ''}),
      "bin_id": new UntypedFormControl({value: null}),
    })

    form.controls['id'].setValue(template.id)
    form.controls['user_id'].setValue(template.user_id)
    form.controls['zip_area'].setValue(template.zip_area)
    form.controls['standby_time'].setValue(template.standby_time)
    form.controls['bin_id'].setValue(template.bin_id)

    form.updateValueAndValidity()

    return form
  }

  protected readonly faUser = faUser;
  protected readonly faIndustry = faIndustry;
  protected readonly faPen = faPen;
  protected readonly faInfoCircle = faInfoCircle;

  protected readonly faCheckCircle = faCheckCircle;

  selectTab(tab: string) {
    this.selectedTab = tab
  }

  getEndDate() {
    if (this.project?.end) {
      return new Date(this.project.end)
    } else {
      return null
    }
  }

  getStartDate() {
    if (this.project?.start) {
      return new Date(this.project.start)
    } else {
      return null
    }
  }

  getProjectTechnicianContext(): FormGroupContext {
    return {
      formGroup: this.projectTechnicianForm,
      createKey: this.createKey
    }
  }

  getProjectStoreContext(): FormGroupContext {
    return {
      formGroup: this.projectStoreForm,
      createKey: this.createKey
    }
  }

  getAGComponents() {
    return {
      'checkboxFormCell': AgGridFormCellComponent<CheckboxInputType>,
      'selectFormCell': AgGridFormCellComponent<SelectInputType>,
    };
  }

  private createKey(api: GridApi, column: Column, row_node: IRowNode): string {
    return `${api.getColumns().indexOf(column)};${row_node.rowIndex}`;
  }

  initProjectTechnicianFormControls() {
    if (this.projectTechnicianGridApi) {
      this.createProjectTechnicianFormControls();
      this.projectTechnicianGridApi.refreshCells({force: true});
    }
  }

  initProjectStoreFormControls() {
    if (this.projectStoreGridApi) {
      this.createProjectStoreFormControls();
      this.projectStoreGridApi.refreshCells({force: true});
    }
  }

  private createProjectTechnicianFormControls() {
    let columns = this.projectTechnicianGridApi.getColumns();

    const form_group = (<UntypedFormGroup>this.projectTechnicianForm);

    // clear out old form group controls if switching between branches
    /*let controlNames = Object.keys(form_group.controls);
    controlNames.forEach((controlName) => {
      form_group.removeControl(controlName)
    });*/

    this.projectTechnicianGridApi.forEachNode((rowNode: IRowNode, idx: number) => {
      columns.filter((column: Column) => column.getColDef().field !== "name")
        .forEach((column: Column<InputType>) => {
          const key = this.createKey(this.projectTechnicianGridApi, column, rowNode); // the cells will use this same createKey method
          form_group.addControl(key, new FormControl());
          let control = form_group.controls[key]
          let input = this.projectTechnicianFormCellInputs[idx][column.getId()]
          control.setValue(input.value);
          if (input?.disabled) control.disable();
        });
    });
  }

  private createProjectStoreFormControls() {
    let columns = this.projectStoreGridApi.getColumns();

    const form_group = (<UntypedFormGroup>this.projectStoreForm);

    // clear out old form group controls if switching between branches
    /*let controlNames = Object.keys(form_group.controls);
    controlNames.forEach((controlName) => {
      form_group.removeControl(controlName)
    });*/

    this.projectStoreGridApi.forEachNode((rowNode: IRowNode, idx: number) => {
      columns.filter((column: Column) => !["store.nr","store.name","store.address"].includes(column.getColDef().field))
        .forEach((column: Column<InputType>) => {
          const key = this.createKey(this.projectStoreGridApi, column, rowNode); // the cells will use this same createKey method
          form_group.addControl(key, new FormControl());
          let control = form_group.controls[key]
          let input = this.projectStoreFormCellInputs[idx][column.getId()]
          control.setValue(input.value);
          if (input?.disabled) control.disable();
        });
    });
  }

  getProjectTechnicianRowNodeId(data: {data: ProjectTechnicianFormCellInput}): string {
    return data.data.user_id.toString();
  }

  getStoreRowNodeId(data: {data: ProjectStoreFormCellInput}): string {
    return data.data.store.id.toString();
  }

  protected readonly faUpload = faUpload;

  onProjectTechnicianSubmit() {

  }

  onProjectStoreSubmit() {

  }

  private generateProjectTechnicianFormCellInputs() {
    this.projectTechnicianFormCellInputs = this.technicians.map(
      t => {
        const pt = this.project.project_technicians.find(pt => pt.user_id == t.id)

        return {
          name: t.name,
          user_id: t.id,
          bin_input: {
            type: 'select_groups',
            value: pt?.bin_id || null,
            opt_groups: pt ? pt.technician.warehouses.map(w => {
              return {
                label: w.label,
                options: w.bins
              }
            }) : [],
            disabled: !pt,
            null_option: true
          },
          selected_input: {
            type: 'checkbox',
            value: !!pt
          },
        }
      }
    )
  }

  private generateStoreFormCellInputs(): void {
    this.projectStoreFormCellInputs = this.customerStores.map(s => {
      const ps = this.project.stores.find(ps => ps.id == s.id)
      return {
        store: s,
        selected_input: {
          type: 'checkbox',
          value: !!ps
        }
      }
    })
  }
}
