<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar common_style" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 894px;">
      <div class="wrapper" style="padding: 2px;">
        <div class="callout" style="margin: 10px;">
          <h4>HINWEIS</h4>
          <div>Mailtemplates können den einzelnen Status des Projektes zugeordnet werden. Sobald ein solcher Status in einem Ticket erreicht wird, wird eine Mail an die hinterlegten Kontakte des Projektes gesendet.</div>
          <div>Hier können Sie diese Mailbenachrichtigung anpassen, insbesondere können Sie festlegen welche Daten des Tickets mit in der Mail versendet werden sollen.</div>
        </div>

        <section>
          <h2>Mailtemplate bearbeiten</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">
                Template-Typ*
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <mat-select formControlName="type">
                    <mat-option></mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Betreff*
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input type="text" matInput formControlName="subject">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Text*
              </div>
              <div class="input_container" style="margin-bottom: 12px;">
                <mat-form-field>
                  <mat-hint>Im Betreff und im Text können Sie folgende Platzhalter verwenden: <code>{{"${ticket.orderNr} ${ticket.status} ${ticket.priority} ${ticket.externalOrderNr} ${ticket.description}"}}</code></mat-hint>
                  <textarea matInput formControlName="subject"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Ticketfelder</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">Daten</div>
              <div class="input_container" style="flex-wrap: wrap;">
                <mat-checkbox color="primary" class="mr8" formControlName="orderNr">Auftragsnummer</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="status">Status</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="reportLink">Link zum Arbeitsbericht</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="dateSLA">SLA-Zeitpunkt</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operationDate">Einsatzzeitpunkt</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="tasks">Aufgaben</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="priority">Priorität</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="dateCreated">Datum der Ticketerstellung</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="externalOrderNr">Externe Nummer</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="description">Fehlertext</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="project">Projektname</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="store">Store</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="address">Adresse</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="deliveryNotes">Lieferungen</mat-checkbox>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">Kontakt</div>
              <div class="input_container">
                <mat-checkbox color="primary" class="mr8" formControlName="contactName">Name</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="contactEmail">E-Mail</mat-checkbox>
                <mat-checkbox color="primary" formControlName="contactPhone">Telefon</mat-checkbox>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">Servicebericht</div>
              <div class="input_container">
                <mat-checkbox color="primary" formControlName="combined_report">Ticket-Servicebericht (Zusammenfassung)</mat-checkbox>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Einsatzdaten</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">Daten</div>
              <div class="input_container" style="flex-wrap: wrap;">
                <mat-checkbox color="primary" class="mr8" formControlName="operation.date">Einsatzdatum</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.status">Status</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.technicians">Techniker</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.description">Beschreibung</mat-checkbox>
                <mat-checkbox color="primary" formControlName="operation.report">Report</mat-checkbox>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">Alt</div>
              <div class="input_container" style="flex-wrap: wrap;">
                <mat-checkbox color="primary" class="mr8" formControlName="operation.travelTimeStart">Fahrzeit Begin</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.travelTimeEnd">Fahrzeit Ende</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.travelTime">Fahrzeit</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.workingTimeStart">Arbeitszeit Begin</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.workingTimeEnd">Arbeitszeit Ende</mat-checkbox>
                <mat-checkbox color="primary" formControlName="operation.workingTime">Arbeitszeit</mat-checkbox>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">Neu</div>
              <div class="input_container" style="flex-wrap: wrap;">
                <mat-checkbox color="primary" class="mr8" formControlName="operation.dateTravelStart">Zeitpunkt: Reisebeginn</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.dateOnSite">Zeitpunkt: Vor Ort</mat-checkbox>
                <mat-checkbox color="primary" class="mr8" formControlName="operation.dateRepaired">Zeitpunkt: Repariert</mat-checkbox>
                <mat-checkbox color="primary" formControlName="operation.dateFinished">Zeitpunkt: Fertig</mat-checkbox>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">Arbeitsbericht</div>
              <div class="input_container">
                <div class="input_container" style="flex-wrap: wrap;">
                  <mat-checkbox color="primary" class="mr8" formControlName="operation.distance">Entfernung</mat-checkbox>
                  <mat-checkbox color="primary" class="mr8" formControlName="operation.files">Alle Dateien des Arbeitsberichtes der Mail anhängen</mat-checkbox>
                  <mat-checkbox color="primary" class="mr8" formControlName="operation.add_report_photo">Foto des Arbeitsberichts aus Schritten einfügen (papierbericht.check.ja)</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="operation.dont_send_report">Einsatzbericht nicht anfügen</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>