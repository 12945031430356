<form *ngIf="formGroup" class="common_style" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar common_style" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 1024px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Projekt-Techniker bearbeiten</h4>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              PLZ-Gebiete
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small" appearance="outline">
                <input matInput formControlName="zip_area">
                <mat-hint>Einzelne PLZ oder Bereiche kommagetrennt, z.B. '10000,10200,20000-29999'</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Bereitschaftszeit
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small" appearance="outline">
                <input matInput formControlName="standby_time">
                <mat-hint>Zeiten in denen der Techniker kontaktiert werden kann, Zeitbereiche kommagetrennt, z.B. '08:00-12:00,13:00-18:00'</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Lagerplatz
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small" appearance="outline">
                <mat-select formControlName="bin_id">
                  <mat-option [value]="null">Keinen Platz zuordnen</mat-option>
                  <mat-optgroup *ngFor="let warehouse of warehouses" [label]="'Lager: ' + warehouse.label">
                    <mat-option *ngFor="let bin of (warehouse.bins || [])" [value]="bin.id">Platz: {{bin.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>