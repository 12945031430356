// src/app/shared/simple-json-diff/simple-json-diff.component.ts
// (Adjust path as needed)
import { Component, Input, ChangeDetectionStrategy, LOCALE_ID, Inject } from '@angular/core'
import { CommonModule, formatDate, TitleCasePipe } from '@angular/common'
import { isEqual as lodashIsEqual } from 'lodash-es'
import { MtChecklistEntry, MtResultItem, MtSstResult } from 'src/app/_models/mt-result.interface' // Adjust path as needed

@Component({
  selector: 'app-simple-json-diff',
  standalone: true,
  imports: [CommonModule, TitleCasePipe],
  templateUrl: './simple-json-diff.component.html',
  styleUrls: ['./simple-json-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleJsonDiffComponent {
  @Input() public current: any
  @Input() public original: any
  @Input() public level: number = 0

  public isEqual = lodashIsEqual

  // Inject locale for date formatting
  public constructor(@Inject(LOCALE_ID) private locale: string) {}

  public getKeys(obj: any): string[] {
    const currentKeys = obj && typeof obj === 'object' ? Object.keys(obj) : []
    const originalKeys = this.original && typeof this.original === 'object' ? Object.keys(this.original) : []
    // Ensure we only work with keys from actual objects
    const validCurrentKeys = typeof this.current === 'object' ? currentKeys : []
    const validOriginalKeys = typeof this.original === 'object' ? originalKeys : []
    const combined = Array.from(new Set([...validCurrentKeys, ...validOriginalKeys]))

    if (this.level === 0) {
      // Sort top-level keys, putting items/manual_items first
      const specialKeys = ['items', 'manual_items']
      return [
          ...specialKeys.filter(k => combined.includes(k)),
          ...combined.filter(k => !specialKeys.includes(k)).sort()
      ]
    } else {
      // Sort keys within items/objects, putting sst_results/sst_ids first
      const specialKeys = ['sst_results', 'sst_ids'] // sst_ids is included for sorting, might be hidden later
      return [
          ...specialKeys.filter(k => combined.includes(k)),
          ...combined.filter(k => !specialKeys.includes(k)).sort()
       ]
    }
  }

  // --- Item Handling Methods ---

  /**
   * Combines item IDs from current and original 'items' objects.
   */
  public getItemIds(currentItems: Record<string, MtResultItem> | null | undefined, originalItems: Record<string, MtResultItem> | null | undefined): string[] {
    const currentKeys = currentItems ? Object.keys(currentItems) : []
    const originalKeys = originalItems ? Object.keys(originalItems) : []
    return Array.from(new Set([...currentKeys, ...originalKeys])).sort()
  }

  /**
   * Combines serial numbers from current and original 'manual_items' arrays.
   */
  public getManualItemSns(currentItems: MtResultItem[] | null | undefined, originalItems: MtResultItem[] | null | undefined): string[] {
    const currentSns = currentItems?.map(item => item?.item_serial_number).filter(sn => !!sn) as string[] || []
    const originalSns = originalItems?.map(item => item?.item_serial_number).filter(sn => !!sn) as string[] || []
    return Array.from(new Set([...currentSns, ...originalSns])).sort()
  }

  /**
   * Finds a manual item by its serial number in an array.
   */
  public findManualItemBySn(items: MtResultItem[] | null | undefined, sn: string): MtResultItem | undefined {
     return items?.find(item => item?.item_serial_number === sn)
  }
  // --- End Item Handling Methods ---

  // --- SST Handling Methods --
  // 
  //   // --- NEW Checklist Handling Methods ---
  public getChecklistQuestionNumbers(currentChecklist: Record<string, MtChecklistEntry> | null | undefined, originalChecklist: Record<string, MtChecklistEntry> | null | undefined): string[] {
    const currentKeys = currentChecklist ? Object.keys(currentChecklist) : []
    const originalKeys = originalChecklist ? Object.keys(originalChecklist) : []
    // Sortiere numerisch, falls die Keys Zahlen sind
    return Array.from(new Set([...currentKeys, ...originalKeys])).sort((a, b) => {
        const numA = parseInt(a, 10)
        const numB = parseInt(b, 10)
        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB
        }
        return a.localeCompare(b) // Fallback auf String-Sortierung
    })
}
// --- End Checklist Handling Methods ----

  /**
   * Combines SST IDs from current and original 'sst_results' objects.
   */
  public getSstIds(currentSsts: Record<string, MtSstResult> | null | undefined, originalSsts: Record<string, MtSstResult> | null | undefined): string[] {
    const currentKeys = currentSsts ? Object.keys(currentSsts) : []
    const originalKeys = originalSsts ? Object.keys(originalSsts) : []
    return Array.from(new Set([...currentKeys, ...originalKeys])).sort()
  }
  // --- End SST Handling Methods ---

  /**
   * Checks if a value is a non-null object (and not an array).
   */
  public isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value)
  }

  /**
   * Checks if a value is an array.
   */
  public isArray(value: any): boolean {
    return Array.isArray(value)
  }

  /**
   * Checks if the current and original values for a given key are equal (deep comparison).
   */
  public valuesAreEqual(key: string): boolean {
    const currentValue = this.current?.[key]
    const originalValue = this.original?.[key]
    return this.isEqual(currentValue, originalValue)
  }

  /**
   * Checks if a key exists only in the original object (was deleted).
   */
  public isDeleted(key: string): boolean {
    return (!this.current || !(key in this.current)) && (this.original && key in this.original)
  }

  /**
   * Checks if a key exists only in the current object (was added).
   */
  public isAdded(key: string): boolean {
    return (this.current && key in this.current) && (!this.original || !(key in this.original))
  }

  /**
   * Formats a simple value for display. Handles null/undefined, booleans,
   * signature truncation, date formatting (German locale), and attachments.
   */
  public formatSimpleValue(value: any, keyHint: string = ''): string {
    // 1. Null/Undefined Handling
    if (value === null || value === undefined) return '-'

    // 2. Boolean Handling
    if (value === true) return 'Ja'
    if (value === false) return 'Nein'

    // 3. Signature Truncation (if it's a long string and key indicates signature)
    const lowerKeyHint = keyHint.toLowerCase()
    if (
      typeof value === 'string' && value.length > 60 &&
      lowerKeyHint.includes('signature') &&
      !lowerKeyHint.endsWith('_svg') &&
      !lowerKeyHint.endsWith('_time')
    ) {
      return value.substring(0, 50) + '...'
    }

    // 4. Date/DateTime Formatting (German Locale) using ISO-like check
    if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/.test(value)) {
      try {
        const date = new Date(value)
        // Check if time component exists and is not midnight UTC for formatting decision
        const hasTime = value.includes('T') && (date.getUTCHours() !== 0 || date.getUTCMinutes() !== 0 || date.getUTCSeconds() !== 0)
        const format = hasTime ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy'
        const timezone = hasTime ? undefined : 'UTC' // Use UTC for date-only to avoid day shifts
        return formatDate(date, format, this.locale, timezone)
      } catch (e) {
        console.warn(`Date formatting failed for: ${value}`, e)
        // Fallback to string representation if formatting fails
      }
    }

    // 5. Attachment Link Handling
     if (typeof value === 'string' && value.startsWith('/r/secure_link/')) {
       return `[Anhang: ${value.split('/').pop()}]`
     }

    // 6. Fallback to string representation
    const stringValue = String(value)
    return stringValue
  }

  /**
   * Calculates the indentation style based on the current level.
   */
  public getIndentStyle(): { 'padding-left': string } {
    const padding = this.level > 0 ? this.level * 20 : 0
    return { 'padding-left': `${padding}px` }
  }

  /**
   * Checks if the key represents a top-level item section ('items' or 'manual_items').
   */
  public isTopLevelItemSectionKey(key: string): boolean {
    return this.level === 0 && (key === 'items' || key === 'manual_items')
  }
}