import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormModalComponent } from "../form-modal-component.class";
import { FullProjectHash } from "../../../../_models/project.interface";
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatFormField, MatHint } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { NgIf } from "@angular/common";

@Component({
  selector: 'hawk-edit-project-deadlines-modal',
  imports: [
    MatButton,
    MatCheckbox,
    MatFormField,
    MatHint,
    MatInput,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './edit-project-deadlines-modal.component.html',
  standalone: true,
  styleUrls: ['edit-project-deadlines-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class EditProjectDeadlinesModalComponent extends FormModalComponent<FullProjectHash, FullProjectHash> {

  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash>= new EventEmitter<FullProjectHash>();

  override formGroupToDBInput(): FullProjectHash {
    throw new Error('Method not implemented.');
  }

  override getDBCallObservable(object: FullProjectHash): Observable<FullProjectHash> {
    throw new Error('Method not implemented.');
  }

}
