<div class="mt-result-container">
	<div *ngIf="isLoading" class="loading-indicator">
		Lade Daten...
	</div>

	<div *ngIf="error" class="error-message">
		{{ error }}
	</div>

	<ng-container *ngIf="mtResult$ | async as resultData; else noData">
		<div *ngIf="resultData && resultData.current && resultData.original">
			<app-simple-json-diff
				[current]="resultData.current"
				[original]="resultData.original">
			</app-simple-json-diff>
		</div>

		<div *ngIf="resultData && (!resultData.current || !resultData.original)">
				<p>Original- oder aktuelle Daten fehlen für den Vergleich.</p>
				<details *ngIf="resultData.current">
					<summary>Aktuelle Daten</summary>
					<pre>{{ resultData.current | json }}</pre>
				</details>
				<details *ngIf="resultData.original">
					<summary>Original Daten</summary>
					<pre>{{ resultData.original | json }}</pre>
				</details>
		</div>

	</ng-container>

	<ng-template #noData>
		<p *ngIf="!isLoading && !error">Keine Daten verfügbar.</p>
	</ng-template>
</div>