import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Priority, ProjectMetadata, Status, Store, Ticket } from '../_models'
import {
  APProject,
  FullProjectHash,
  PAActiveProject,
  Project, ProjectUpdateParams,
  ProjectWithPrioStatus
} from '../_models/project.interface'
import { APTicket } from "../_models/ticket.interface";
import { CreateTaskAttributes } from "../_models/task.interface";
import { CreateStatusAttributes } from "../_models/status.interface";
import { CreatePriorityAttributes } from "../_models/priority.interface";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  public getAll(params: any = {}) {
    const path = environment.apiURL + 'projects.json'

    return this.http.get<Project[]>(path, { params })
  }

  public getFullProject(id: number | string) {
    const path = environment.apiURL + `projects/${id}.json?hash_type=full_hash`

    return this.http.get<FullProjectHash>(path)
  }

  // admin page hashes
  public getAPProjects() {
    const path = environment.apiURL + "projects.json?hash_type=admin_page_hash"

    return this.http.get<APProject[]>(path)
  }

  public getProject(id: number) {
    const path = environment.apiURL + 'projects/' + id + '.json'

    return this.http.get<Project>(path)
  }

  public getActiveWithPrioStatus() {
    const path = environment.apiURL + 'projects/active_with_prio_status.json'

    return this.http.get<ProjectWithPrioStatus[]>(path)
  }

  public getMetadata(id: string) {
    const path = environment.apiURL + 'projects/' + id + '/metadata.json'

    return this.http.get<ProjectMetadata>(path)
  }

  public updateProject(id: string, params: { id?: string, logo?: string, pdf_show_footer?: boolean, additional_documentation?: string, color?: string }) {
    const path = environment.apiURL + 'projects/update_metadata.json'

    params.id = id

    console.log("updateProject", params)

    return this.http.post<ProjectMetadata>(path, params)
  }

  public updateFullProject(id: string | number, params: ProjectUpdateParams) {
    const path = environment.apiURL + `projects/${id}/update_metadata.json?hash_type=full_hash`

    return this.http.patch<FullProjectHash>(path, params)
  }

  public updateProjectDispatchers(id: string | number, params: { add_dispatcher_ids?: number[], remove_dispatcher_ids?: number[] }) {
    const path = environment.apiURL + `projects/${id}/update_dispatchers.json`

    return this.http.patch<FullProjectHash>(path, params)
  }

  public updateProjectTechnicians(id: string | number, params: { add_technician_ids?: number[], remove_technician_ids?: number[] }) {
    const path = environment.apiURL + `projects/${id}/update_technicians.json`

    return this.http.patch<FullProjectHash>(path, params)
  }


  public getPAActiveProjects() {
    const path = environment.apiURL + 'projects/planning_assistant/active.json'

    return this.http.get<PAActiveProject[]>(path)
  }

  public getPAActiveProject(id: number) {
    const path = environment.apiURL + `project/planning_assistant/${id}/active`

    return this.http.get<PAActiveProject>(path)
  }

  public getStatuses(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/statuses`

    return this.http.get<Status[]>(path)
  }

  public getPriorities(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/priorities`

    return this.http.get<Priority[]>(path)
  }

  public getStores(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/stores`

    return this.http.get<Store[]>(path)
  }

  public getTicketFields(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/ticket_fields`

    return this.http.get<any[]>(path)
  }

  public getProjectTicketStatuses(project_id: number) {
    const path = environment.apiURL + 'projects/' + project_id + '/ticket_statuses.json'

    return this.http.get<{
      ticket: Ticket
      status_id: number,
      status_name: string,
      status_color: string
    }[]>(path)
  }

  public getTickets(id: number | string) {
    const path = environment.apiURL + 'projects/' + id + '/tickets.json'
    return this.http.get<APTicket[]>(path)
  }

  public createProjectStatus(project_id: string | number, params: CreateStatusAttributes) {
    const path = environment.apiURL + `projects/${project_id}/status_id.json`

    return this.http.post<FullProjectHash>(path,  params)
  }

  public updateProjectStatus(project_id: string | number, status_id: string | number, params: CreateStatusAttributes) {
    const path = environment.apiURL + `projects/${project_id}/status_id/${status_id}.json`

    return this.http.patch<FullProjectHash>(path, params)
  }

  public deleteProjectStatus(project_id: string | number, status_id: string | number) {
    const path = environment.apiURL + `projects/${project_id}/status_id/${status_id}.json`

    return this.http.delete<FullProjectHash>(path)
  }

  public createProjectTask(project_id: string | number, params: CreateTaskAttributes) {
    const path = environment.apiURL + `projects/${project_id}/tasks.json`

    return this.http.post<FullProjectHash>(path,  params)
  }

  public updateProjectTask(project_id: string | number, task_id: string | number, params: CreateTaskAttributes) {
    const path = environment.apiURL + `projects/${project_id}/tasks/${task_id}.json`

    return this.http.patch<FullProjectHash>(path, params)
  }

  public deleteProjectTask(project_id: string | number, task_id: string | number) {
    const path = environment.apiURL + `projects/${project_id}/tasks/${task_id}.json`

    return this.http.delete<FullProjectHash>(path)
  }

  public createProjectPriority(project_id: string | number, params: CreatePriorityAttributes) {
    const path = environment.apiURL + `projects/${project_id}/priority.json`

    return this.http.post<FullProjectHash>(path,  params)
  }

  public updateProjectPriority(project_id: string | number, priority_id: string | number, params: CreatePriorityAttributes) {
    const path = environment.apiURL + `projects/${project_id}/priority/${priority_id}.json`

    return this.http.patch<FullProjectHash>(path, params)
  }

  public deleteProjectPriority(project_id: string | number, priority_id: string | number) {
    const path = environment.apiURL + `projects/${project_id}/priority/${priority_id}.json`

    return this.http.delete<FullProjectHash>(path)
  }

}
