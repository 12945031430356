import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Article, Technician, Warehouse } from '../_models'
import { APTechnicianHash, TechnicianUpdateHash, UserAbsence, WPTechnician } from "../_models/technician.interface";
import * as ICAL from 'ical.js';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
      private http: HttpClient,
    ) {
  }

  public getTechnicians(params: any = {}) {
    const path = environment.apiURL + 'technicians.json'
    return this.http.get<Technician[]>(path, { params: params })
  }

  public createUser(create_params: TechnicianUpdateHash, send_account?: boolean) {
    const path = environment.apiURL + `users/create` + (send_account ? '?send_account=true' : '')
    return this.http.post<APTechnicianHash>(path, create_params)
  }

  public updateUser(id: number, update_params: TechnicianUpdateHash, send_account?: boolean) {
    const path = environment.apiURL + `users/${id}` + (send_account ? '?send_account=true' : '')
    return this.http.patch<APTechnicianHash>(path, update_params)
  }

  public deleteUser(id: number) {
    const path = environment.apiURL + `users/${id}`
    return this.http.delete<APTechnicianHash>(path)
  }

  public userIsDeletable(id: number) {
    const path = environment.apiURL + `users/${id}/deletable`
    return this.http.get<{deletable: boolean}>(path)
  }

  public updateUserRoles(id: number, add_roles_with_ids: number[], delete_roles_with_ids: number[]) {
    const path = environment.apiURL + `users/${id}/update_roles`

    const data = {
      roles: {
        add_roles_with_ids,
        delete_roles_with_ids
      }
    }

    return this.http.post<APTechnicianHash>(path, data)
  }

  placeHolderTechnicians() {
    const path = environment.apiURL + 'technicians/placeholder'

    return this.http.get<Technician[]>(path)
  }

  // Get Admin Page Hashes
  public getUsersAPHashes() {
    const path = environment.apiURL + 'users/all'

    let params = new HttpParams()
    params = params.append('hash_type', 'admin_page_hash')

    return this.http.get<APTechnicianHash[]>(path, {params})
  }

  // Get Welcome Page Hashes
  public getUsersWPHashes() {
    const path = environment.apiURL + 'users/all'

    let params = new HttpParams()
    params = params.append('hash_type', 'welcome_page_hash')

    return this.http.get<WPTechnician[]>(path, {params})
  }

  // Get Admin Page User
  public getAPUser(id: number) {
    const path = environment.apiURL + `users/${id}`

    let params = new HttpParams()
    params = params.append('hash_type', 'admin_page_hash')

    return this.http.get<APTechnicianHash>(path, {params})
  }

  public getUsersWarehouses(id: number | string) {
    const path = environment.apiURL + `users/${id}/warehouses`

    return this.http.get<Warehouse[]>(path)
  }

  getSubcontractors() {
    const path = environment.apiURL + `subcontractors`
    return this.http.get<Technician[]>(path)
  }

  getDispatcher() {
    const path = environment.apiURL + `dispatcher`
    return this.http.get<Technician[]>(path)
  }

  public getProjectTechnicians(projectId: number) {
    const path = environment.apiURL + 'project/' + projectId + '/technicians.json'
    return this.http.get<Technician[]>(path)
  }

  public priorityBasedOperationTimes(uid: number, priority_id: number) {
    let params = new HttpParams()

    params = params.append('pid', priority_id.toString())

    const path = environment.apiURL + 'users/' + uid + '/priority_based_operation_times'

    return this.http.get<{operation_id: number, duration: number, date: string}[]>(path, {params})
  }

  public priority_operation_count(uid: number, priority_id: number) {
    let params = new HttpParams()

    params = params.append('priority_id', priority_id.toString())

    const path = environment.apiURL + 'users/' + uid + '/priority_operation_count'

    return this.http.get<{user_id: number, priority_id: number, done_priority_operations_count: number}>(path, {params})
  }

  public project_operation_count(uid: number, project_id: number) {
    let params = new HttpParams()

    params = params.append('project_id', project_id.toString())

    const path = environment.apiURL + 'users/' + uid + '/project_operation_count'

    return this.http.get<{user_id: number, project_id: number, done_project_operations_count: number}>(path, {params})
  }

  public distancesToCoordinates(uids: number[], lat: number, lng: number) {

    let params = new HttpParams()

    for (let uid of uids) {
      params = params.append('ids[]', uid.toString())
    }

    params = params.append('lat', lat.toString())
    params = params.append('lng', lng.toString())

    const path = environment.apiURL + 'users/distance_to_coordinates'

    return this.http.get<{id: number, distance: number, duration: number, valid: boolean}[]>(path, {params})
  }

  public getArticles(uid: number) {
    const path =  environment.apiURL + `users/${uid}/articles`
    return this.http.get<Article[]>(path)
  }

  public getUserIdsWithOperationsBetweenDates(date_start: string, date_until: string, uids?: number[]) {

    let params = new HttpParams()

    if (uids) {
      for (let uid of uids) {
        params = params.append('ids[]', uid.toString())
      }
    }

    params = params.append('date_start', date_start)
    params = params.append('date_until', date_until)

    const path = environment.apiURL + 'user_ids_with_operations_between_dates'

    return this.http.get<number[]>(path, {params})
  }

  public async downloadUserAbsenceCalendar(): Promise<UserAbsence[]> {
    const absences: UserAbsence[] = []
    try {
      const response = await fetch('https://app.absence.io/ics/company/943e46c58dda53d42b748fb23c18d4c3c01c58c4783eeadc1c2ec36f1b7fb15b8a/cal.ics');

      if (response.status != 200) {
        throw new Error(`Unable to download file. HTTP status: ${response.status}`);
      }

      const text = await response.text()
      let ical_data = ICAL.parse(text)
      try {
        for (let vevent of ical_data[2]) {
          const dt_start = vevent[1].filter(property => property[0] == 'dtstart')[0][3]
          const dt_end = vevent[1].filter(property => property[0] == 'dtend')[0][3]
          const summary: string = vevent[1].filter(property => property[0] == 'summary')[0][3]
          const summary_split = summary.split(' - ')
          const type = summary_split[0]
          const employee_name = summary_split[1]
          const status = vevent[1].filter(property => property[0] == 'status')[0][3]
          if (['Abbau Überstunden', 'Urlaub', 'Krankheit'].includes(type)) {
            absences.push({
              status: status,
              employee_name: employee_name,
              from: new Date(dt_start),
              until: new Date(dt_end),
              type: type
            })
          }
        }
      } catch (error) {
        console.error('Calendar parsing error', error)
      }
    } catch (error) {
      console.error('Error downloading the bentomax calendar file:', error);
    }
    return absences
  }
}
