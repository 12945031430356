<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 894px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Priorität bearbeiten</h4>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              Name*
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Farbe*
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input type="color" matInput formControlName="color">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              SLA-Typ
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-select formControlName="sla_type">
                  <mat-option value="NO"> Kein SLA </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container flex_row">
              <div class="icon_wrapper mr24">
                <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                  <input type="number" min="0" matInput formControlName="days">
                </mat-form-field>
                <div class="icon_container" style="padding: 0 12px">
                  <div>
                    Tage
                  </div>
                </div>
              </div>
              <div class="icon_wrapper">
                <mat-form-field style="width: 80px" class="outline-small no-subscript" appearance="outline">
                  <input type="number" min="0" matInput formControlName="hours">
                </mat-form-field>
                <div class="icon_container" style="padding: 0 12px">
                  <div>
                    h
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="material_return">Materialrücksendung</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>