import {Component} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgForOf, NgIf } from "@angular/common";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { MatOption, MatSelect, MatSelectModule } from "@angular/material/select";
import { MatCheckbox } from "@angular/material/checkbox";

export type FormGroupContext = {
  createKey: (...args: any[]) => any,
  formGroup: FormGroup
}

export type InputType = CheckboxInputType | SelectInputType

export type CheckboxInputType = {
  type: 'checkbox',
  value: boolean
}

export type SelectInputType = {
  type: 'select',
  value: number
  options: SelectInputOption[]
} | {
  type: 'select_groups',
  value: number
  opt_groups: SelectInputOptGroup[]
  null_option?: boolean
  disabled?: boolean
}

export type SelectInputOptGroup = {
  label: string,
  options: SelectInputOption[]
}

export type SelectInputOption = {
  id: number,
  name: string
}


@Component({
  selector: 'app-form-cell',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatSelectModule,
    NgForOf,
    MatCheckbox
  ],
  template: `
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <mat-form-field *ngIf="tValue.type=='select' || tValue.type=='select_groups'" style="width: 100%;background-color: #fdfdfd;"
                      class="outline-small no-subscript" appearance="outline">
        <mat-select [formControlName]="key" [id]="key">

          <ng-container *ngIf="tValue.type=='select'">
            <mat-option *ngFor="let option of selectOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </ng-container>

          <ng-container *ngIf="tValue.type=='select_groups'">
            <mat-option *ngIf="showNullValueOption" [value]="null">
              -
            </mat-option>
            <mat-optgroup *ngFor="let group of selectOptGroups" [label]="group.label">
              <mat-option *ngFor="let option of group.options" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>

        </mat-select>
      </mat-form-field>

      <mat-checkbox color="primary" *ngIf="tValue.type=='checkbox'" [formControlName]="key"></mat-checkbox>
    </div>
  `,
  styleUrl: './../styles/common-styles.scss'
})
export class AgGridFormCellComponent<TValue extends InputType> implements ICellRendererAngularComp {
  formGroup: FormGroup;
  key: any;
  public tValue: TValue;
  columnName: string;

  selectOptions: SelectInputOption[]
  selectOptGroups: SelectInputOptGroup[]
  showNullValueOption: boolean

  agInit(params: ICellRendererParams<any,TValue,FormGroupContext>) {
    this.columnName = params.colDef.headerName;
    this.key = params.context.createKey(params.api, params.column, params.node);
    this.tValue = params.value;

    if (this.tValue.type == 'select') {
      this.selectOptions = this.tValue.options
    } else if (this.tValue.type == 'select_groups') {
      this.selectOptGroups = this.tValue.opt_groups
      this.showNullValueOption = this.tValue.null_option
    }

    this.formGroup = params.context.formGroup;
  }

  refresh(params: ICellRendererParams<any,TValue,FormGroupContext>): boolean {
    this.formGroup = params.context.formGroup;
    this.formGroup.controls[this.key].patchValue(this.tValue);
    return true;
  }

}