import { Component, inject } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";
import { MatButton } from "@angular/material/button";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import {
  GridApi,
  RowClassRules, SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { ProjectService } from "../../_services";
import { APProject } from "../../_models/project.interface";
import { MatCheckbox } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'hawk-project-index',
  imports: [
    AgGridAngular,
    MatButton,
    RecordNavigationComponent,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './project-index.component.html',
  standalone: true,
  styleUrls: ['./project-index.component.scss', '../_shared/styles/common-styles.scss']
})
export class ProjectIndexComponent {
  public hawkTheme = inject(AgGridThemeService).getTheme();

  protected readonly locale = AG_GRID_LOCALE_DE;

  public projects: APProject[] = []
  public shownProjects: APProject[] = []
  public onlyActiveProjects: boolean = true

  private gridApi!: GridApi;

  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public colDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: this.nameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "customer",
      headerName: "Kunde",
      cellRenderer: this.customerRenderer,
    },
    {
      field: "type",
      headerName: "Typ",
      cellRenderer: this.typeRenderer,
    },
    {
      field: "description",
      headerName: "Beschreibung",
    },
    {
      field: "active",
      headerName: "Aktiv",
      width: 90,
      maxWidth: 90,
    },
  ]

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "multiple"

  constructor(
    public projectService: ProjectService
  ) {
  }

  ngOnInit(): void {
    this.projectService.getAPProjects().subscribe(
      data => {
        this.projects = data
        this.updateShownProjects()
      },
      err => {
        console.log(err)
      }
    )
  }

  updateShownProjects(): void {
    this.shownProjects = this.projects.filter(u => !this.onlyActiveProjects || u.active)
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public quickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  telephoneRenderer(params) {
    const phone = params.value
    return `<a href="tel:${phone ? phone.replaceAll(' ', '') : ''}" target="_blank" rel="noopener">${phone || ''}</a>`
  }

  typeRenderer(params) {
    const type_translations = {
      'PROJECT_BUSINESS': 'Projektgeschäft',
      'MAINTENANCE': 'Wartung'
    }
    const type_translation = type_translations[params.value]
    return type_translation || params.value
  }

  customerRenderer(params) {
    const customer_id = params.value.id
    return `<a class="hover_link" href="/a/kunde/${customer_id}" rel="noopener">${params.value.name}</a>`
  }

  nameRenderer(params) {
    const id = params.data.id
    return `<a class="hover_link" href="/a/projekt/${id}" rel="noopener">${params.value}</a>`
  }
}
