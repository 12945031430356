
<hawk-record-navigation class="common_style" [breadcrumbLinkList]="[{description: 'Projekte', link: '/a/projekt'}]" [root]="'project'" [leafDescription]="'Neues Projekt'">
  <form [formGroup]="projectForm" style="width: 1024px;" class="normal-form" (submit)="onSubmit()">
    <section>
      <h2>Stammdaten</h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            Name*
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small no-subscript" appearance="outline">
              <input type="text" matInput formControlName="name">
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Beschreibung
          </div>
          <div class="input_container">
            <mat-form-field class="no-subscript">
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Typ*
          </div>
          <div class="input_container">
            <mat-radio-group color="primary" aria-label="Select an option" formControlName="type">
              <mat-radio-button value="MAINTENANCE">Wartung</mat-radio-button>
              <mat-radio-button value="PROJECT_BUSINESS">Projektgeschäft</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Kunde*
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small no-subscript" appearance="outline">
              <mat-select formControlName="customer_id">
                <mat-option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Kostenstelle
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small no-subscript" appearance="outline">
              <input type="text" matInput formControlName="cost_center">
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Cut off time
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small" appearance="outline">
              <input type="time" matInput formControlName="cut_off_time">
              <mat-hint>Ab diesem Zeitpunkt verlängert sich die SLA um einen Tag. Format: '15:00'</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
          </div>
          <div class="input_container">
            <mat-checkbox color="primary" formControlName="select_products">Produktauswahl aktiviert</mat-checkbox>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Kontakt</h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            Daten
          </div>
          <div class="input_container">
            <mat-form-field class="no-subscript">
              <textarea matInput formControlName="contact"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            E-mail
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small" appearance="outline">
              <input matInput formControlName="email">
              <mat-hint>Mehrere E-Mails können mit einem Semikolon getrennt werden. Diese erhalten Nachrichten über Statusänderungen</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Zeitraum</h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            Start
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small no-subscript" appearance="outline">
              <input matInput [matDatepicker]="picker_start" formControlName="start">
              <mat-datepicker-toggle matIconSuffix [for]="picker_start"></mat-datepicker-toggle>
              <mat-datepicker #picker_start></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Ende
          </div>
          <div class="input_container">
            <mat-form-field class="outline-small no-subscript" appearance="outline">
              <input matInput [matDatepicker]="picker_end" formControlName="end">
              <mat-datepicker-toggle matIconSuffix [for]="picker_end"></mat-datepicker-toggle>
              <mat-datepicker #picker_end></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>
        Mailbenachrichtigungen
        <fa-icon style="cursor: pointer;" [icon]="faInfoCircle" tooltip="Alle Techniker eines Einsatzes erhalten bei folgenden Ereignissen eine E-Mail"></fa-icon>
      </h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
          </div>
          <div class="input_container">
            <mat-checkbox color="primary" formControlName="send_operation_new">Einsatz wurde neu erstellt</mat-checkbox>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
          </div>
          <div class="input_container">
            <mat-checkbox color="primary" formControlName="send_operation_update">Einsatz wurde geändert</mat-checkbox>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
          </div>
          <div class="input_container">
            <mat-checkbox color="primary" formControlName="send_operation_delete">Einsatz wurde gelöscht (Storno)</mat-checkbox>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Dokumentation</h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            URL
          </div>
          <div class="input_container">
            <mat-form-field class="no-subscript">
              <textarea matInput formControlName="additional_documentation"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </section>
    <div style="margin-bottom: 8px; margin-left: 8px;" class="flex_row">
      <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!projectForm.valid">Projekt erstellen</button>
      <a style="margin-left: 8px;" href="/a/projekt">
        <button type="button" mat-flat-button class="primary_color_button">Abbrechen</button>
      </a>
    </div>
  </form>
</hawk-record-navigation>
