<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 894px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Status bearbeiten</h4>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              Name*
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Standardaufgaben
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-label>Wählen Sie eine oder mehrere Aufgaben aus...</mat-label>
                <mat-select formControlName="task_ids" multiple>
                  <mat-option>Test</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="closed">Ticket geschlossen</mat-checkbox>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="canceled">Ticket storniert</mat-checkbox>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="report_closed">Arbeitsbericht kann von Technikern nicht geändert werden</mat-checkbox>
            </div>
          </div>
          <div class="input_row" style="margin-top: 12px;">
            <div class="input_label"></div>
            <div class="input_container">
              <mat-checkbox color="primary" formControlName="fixer_forbidden">Ticketfixer deaktiviert</mat-checkbox>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Farbe
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input type="color" matInput formControlName="color">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Mailtemplate
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-select formControlName="mail_template_id">
                  <mat-option value="null">-</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              E-Mail
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="email">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left"></div>
        <div class="modal-toolbar-container-right">
          <button class="mr6" mat-flat-button type="button" (click)="closeModal.emit()">Abbrechen</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!formGroup.valid">Speichern</button>
        </div>
      </div>
    </div>
    <button type="button" class="modal-close" aria-label="close" (click)="closeModal.emit()"></button>
  </div>
</form>