<hawk-record-navigation [breadcrumbLinkList]="[]" [root]="'project'" [leafDescription]="'Projekte'">
  <div class="flex_row" style="text-align: right; width: 100%; padding: 8px;">
    <div style="flex: 1;"></div>
    <mat-checkbox style="margin-right: 8px"
      [(ngModel)]="onlyActiveProjects"
      (change)="updateShownProjects()"
      color="primary"
    >
    Nur aktive Projekte
  </mat-checkbox>
    <input type="text" style="margin: auto 10px;" placeholder="Schnellfilter..." (input)="quickSearchChanged($event)">
  </div>
  <div class="card-body">
    <ag-grid-angular class="ag-theme-quartz" style="height: 710px; width: 100%;" [rowData]="shownProjects" [theme]="hawkTheme"
                     [defaultColDef]="defaultColDef" [columnDefs]="colDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                     [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                     [paginationPageSize]="25" [localeText]="locale" [sideBar]="sideBarOptions"
                     [paginationPageSizeSelector]="[25,100,250,1000]" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <div class="flex_row">
      <a style="margin-top: 8px;" href="/a/projekt/neu">
        <button mat-raised-button color="primary" class="primary_bg_color_button" >Neues Projekt</button>
      </a>
    </div>
  </div>
</hawk-record-navigation>
