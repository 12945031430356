import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { ArticleTemplate, JobAssignment, Technician, Operation, TicketMetadata, Ticket, Priority, Material, DeliveryNote, TicketLocation, ArticleSummary, Status, TicketSearchParams } from '../_models'
import { TicketJobResponse } from '../_models/ticket-job-response.model'
import { Observable } from 'rxjs'
import { Opening } from '../_models/opening.interface'
import { PermittedTicketParams, TicketCoordinates, WPTicket } from '../_models/ticket.interface'
import { PermittedOperationParams } from '../_models/operation.interface'
import { MtResultResponse } from '../_models/mt-result.interface'

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) {}

  public createMaterial(id: number, data: any) {
    const path = environment.apiURL + 'tickets/' + id + '/create_material.json'

    return this.http.post<Material>(path, data)
  }

  public linkDeliveryNote(ticketId: number, deliveryNoteNumber: string) {
    const path = environment.apiURL + 'tickets/' + ticketId + '/link_delivery_note.json'

    const data = {
      delivery_note_number: deliveryNoteNumber
    }

    return this.http.post<DeliveryNote[]>(path, data)
  }

  public unlinkDeliveryNote(ticketId: number, deliveryNoteNumber: number) {
    const path = environment.apiURL + 'tickets/' + ticketId + '/unlink_delivery_note/' + deliveryNoteNumber + '.json'

    return this.http.delete<DeliveryNote[]>(path)
  }

  public getDeliveryNotes(ticketId: number) {
    const path = environment.apiURL + 'tickets/' + ticketId + '/delivery_notes.json'

    return this.http.get<DeliveryNote[]>(path)
  }

  public getTicketDetails(id: number) {
    const path = environment.apiURL + 'tickets/' + id + '.json'
    return this.http.get<ArticleTemplate>(path)
  }

  public getTechnicianTicketLocations(id: number, date: string | Date) {
    const path = environment.apiURL + 'technicians/' + id + '/tickets/' + date + '.json'
    return this.http.get<TicketLocation[]>(path)
  }

  public getTicket(id: string) {
    const path = environment.apiURL + 'tickets/' + id + '.json'
    return this.http.get<Ticket>(path)
  }

  public getOpenServiceTickets() {
    const path = environment.apiURL + 'tickets/service/open'

    return this.http.get<WPTicket[]>(path)
  }

  public updateTicket(id: string, data: any) {
    const path = environment.apiURL + 'tickets/' + id + '.json'

    return this.http.patch<Ticket>(path, data)
  }

  public updateToRejectTicket(id: string, data: any) {
    const path = environment.apiURL + 'tickets/' + id + '/update_to_reject.json'

    return this.http.patch<Ticket>(path, data)
  }

  public updateTicketStatus(id: string, data: any) {
    const path = environment.apiURL + 'tickets/' + id + '/status.json'

    return this.http.patch<Ticket>(path, data)
  }

  public getTicketStatus(id: string) {
    const path = environment.apiURL + 'tickets/' + id + '/status.json'

    return this.http.get<Status>(path)
  }

  public update(id: string | String | number, params: any) {
    const path = environment.apiURL + 'tickets/'+ id +'.json'
    return this.http.patch<Ticket>(path, params)
  }
  
  public create(params: any) {
    const path = environment.apiURL + 'tickets/create.json'
    return this.http.post<Ticket>(path, params)
  }

  public deleteTicket(id: number) {
    const path = environment.apiURL + 'tickets/' + id + '.json'

    return this.http.delete<Ticket>(path)
  }

  public deleteOperation(id: number) {
    const path = environment.apiURL + 'operations/' + id + '.json'

    return this.http.delete<Operation>(path)
  }

  public dangerousDelete(id: number) {
    const path = environment.apiURL + 'tickets/' + id + '/dangerous_delete.json'

    return this.http.delete<Ticket>(path)
  }

  public deleteMaterial(id: number) {
    const path = environment.apiURL + 'materials/' + id 

    return this.http.delete<Material>(path)
  }

  public getTicketJobs(id: string) {
    const path = environment.apiURL + 'tickets/' + id + '/jobs.json'
    return this.http.get<TicketJobResponse>(path)
  }

  public getJobAssignments(weeks: number[]): Observable<JobAssignment[]> {
    const path = environment.apiURL + 'operations/assignment_planning.json'

    let params = new HttpParams()

    weeks.forEach((w) => {
      params = params.append('weeks[]', w.toString())
    })

    return this.http.get<JobAssignment[]>(path, { params })
  }

  public getUnplannedAssignments(weeks: number[]) {
    const path =
      environment.apiURL + 'operations/assignment_planning_unplanned.json'

    let params = new HttpParams()

    weeks.forEach((w) => {
      params = params.append('weeks[]', w.toString())
    })

    return this.http.get<JobAssignment[]>(path, { params })
  }

  public getUnplannedAssignmentsExport(weeks: number[]) {
    const path = environment.apiURL + 'operations/assignment_planning_unplanned.xlsx'

    let params = new HttpParams()

    weeks.forEach(w => {
      params = params.append('weeks[]', w.toString())
    })

    return this.http.get<JobAssignment[]>(path, { params })
  }

  public uploadUnplannedAssignments(data) {

    const path = environment.apiURL + 'operations/assignment_planning/upload'

    return this.http.post<any>(path, data)
  }

  public ticketFixerImport(data) {

    const path = environment.apiURL + 'tickets/fixer_import'

    return this.http.post<any>(path, data)
  }

  public ticketShipmentImport(data) {

    const path = environment.apiURL + 'tickets/shipment_import'

    return this.http.post<any>(path, data)
  }

  public deleteAll() {

    const path = environment.apiURL + 'operations/assignment_planning_unplanned'

    return this.http.delete<any>(path, {})
  }

  public deleteById(ids: number[]) {

    let params = new HttpParams()
    ids.forEach(id => {
      params = params.append('ids[]', id.toString())
    })

    const path = environment.apiURL + 'operations/assignment_planning_unplanned'

    return this.http.delete<any>(path, { params })
  }

  public getTechnicians() {
    const path = environment.apiURL + 'users/all.json'
    return this.http.get<Technician[]>(path)
  }

  public uploadJobImage(data) {
    const path = environment.apiURL + 'app/jobs/upload_image.json'

    return this.http.post<any>(path, data)
  }

  public updateJob(data) {
    const path = environment.apiURL + 'app/jobs/answer.json'

    return this.http.post<any>(path, data)
  }

  public updateJobOperationAssignment(data) {
    const path = environment.apiURL + 'jobs/' + data.id + '/assign_operation/' + data.operation_id + '/.json'

    return this.http.post<any>(path, data)
  }

  public getMetaData(id: number) {
    const path = environment.apiURL + 'tickets/' + id + '/meta_data.json'

    return this.http.get<TicketMetadata>(path)
  }

  public getMtResult(ticketId: number): Observable<MtResultResponse> {
    const path = environment.apiURL + 'tickets/' + ticketId + '/mt_result.json'

    return this.http.get<MtResultResponse>(path);
  }
  
  public getOperations(id: string) {
    const path = environment.apiURL + 'tickets/' + id + '/operations.json'

    return this.http.get<Operation[]>(path)
  }

  public getArticleSummary(id: number){
    const path = environment.apiURL + 'tickets/' + id + '/article_summary.json'

    return this.http.get<ArticleSummary>(path)
  }

  public getMaterials(id: number){
    const path = environment.apiURL + 'tickets/' + id + '/materials'

    return this.http.get<Material[]>(path)
  }

  public getTechniciansWithMaterial(article_template_id: number){
    let params = new HttpParams()
    params = params.append('at_id', article_template_id.toString())

    const path = environment.apiURL + 'user_article_template_material_counts'

    return this.http.get<{id: number, count: number}[]>(path, {params})
  }

  public deleteJob(data) {
    const path = environment.apiURL + 'jobs/' + data.id + '.json'

    return this.http.delete<any>(path)
  }

  public getTicketPriority(ticketId: string) {
    let path = environment.apiURL + "tickets/" + ticketId + "/priority"

    return this.http.get<Priority>(path)
  }

  public storeOpenings(ticketId: string) {
    let path = environment.apiURL + "tickets/" + ticketId + "/store_openings"

    return this.http.get<Opening[]>(path)
  }

  public createTicket(ticket: PermittedTicketParams) {
    let path = environment.apiURL + "tickets/create"

    return this.http.post<Ticket>(path, ticket)
  }

  public createTicketOperation(ticket_id: number, operation_params: {operation: PermittedOperationParams}) {
    let path = environment.apiURL + `tickets/${ticket_id}/create_operation`

    return this.http.post<Operation>(path, operation_params)
  }

  public loadTicketCoordinates(constraints?: { project_id?: number, only_without_operations?: boolean, exclude_material_return?: boolean }): Observable<TicketCoordinates[]> {
    let path = environment.apiURL + `tickets_coordinates`

    let params = new HttpParams()
    if (constraints?.project_id) params = params.append('project_id', constraints.project_id)
    if (constraints?.only_without_operations) params = params.append('only_without_operations', constraints.only_without_operations)
    if (constraints?.exclude_material_return) params = params.append('exclude_material_return', constraints.exclude_material_return)

    return this.http.get<TicketCoordinates[]>(path, {params})
  }

  public searchTicket(searchParams: TicketSearchParams) {
    let path = environment.apiURL + "tickets/search"

    return this.http.post<Ticket[]>(path, searchParams)
  }
}
