import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import Utils from 'src/app/_helpers/utils'
import { Article, ArticleHandling, ArticleSummary, ArticleTemplate, EditEventType, Operation, OperationFile, Part, StoreUnit, Technician, Ticket } from 'src/app/_models'
import { MetaDataArticleOutput, OperationMetadata, OperationMetadataStep } from 'src/app/_models/operation.interface'
import { ArticleService, OperationService, StoreService, TicketService, WorkflowService } from 'src/app/_services'
import { EditInputComponent } from "../../_shared/edit-input/edit-input.component";
import { OperationSubpageStepArticleComponent } from "../operation-subpage-step-article/operation-subpage-step-article.component";
import { ArticleFilterPipe, ArticleTemplateFilterPipe, TechnicianFilterPipe } from "src/app/_pipes";
import { NgxFilesizeModule } from "ngx-filesize";
import { FileInputComponent } from "../../_shared/file-input/file-input.component";
import { WorkflowUploadComponent } from "src/app/workflow-upload/workflow-upload.component";
import { MtResultComponent } from "../../mt-result/mt-result.component";

@Component({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, FontAwesomeModule, EditInputComponent, OperationSubpageStepArticleComponent, ArticleTemplateFilterPipe, NgxFilesizeModule, TechnicianFilterPipe, ArticleFilterPipe, FileInputComponent, WorkflowUploadComponent, MtResultComponent],
    selector: 'hawk-operation-subpage',
    templateUrl: './operation-subpage.component.html',
    styleUrls: ['./operation-subpage.component.scss'],
})
export class OperationSubpageComponent implements OnInit, OnChanges {
  @Input() operation: Operation
  @Input() ticket: Ticket
  @Input() technicians: Technician[]
  @Input() articleTemplates: ArticleTemplate[]
  @Input() articleSummary: ArticleSummary
  @Input() isBookkeeper: boolean
  @Input() canReject: boolean
  @Output() reloadTicket = new EventEmitter<boolean>();
  @Output() selectStep = new EventEmitter<OperationMetadata>();

  public parts: Part[] = []
  public files: OperationFile[] = []
  public storeUnits: StoreUnit[] = []
  public selectedPart: Part
  public articleHandling: ArticleHandling[] = []
  public descriptionLinebreaks = false
  public reportLinebreaks = false
  public selectedTab = 'overview'

  public editDescription = false
  public editTechnicianReport = false

  public editStatus = false
  public editTravelStart = false
  public editArrival = false
  public editRepaired = false
  public editFinished = false
  public editDistance = false
  public editOperationDate = false
  public editTechnicians = false
  public editInvoiceCustomer = false
  public editInvoiceAmount = false
  public editInvoicePOAmount = false
  public editInvoicePOXAmount = false
  public editInvoicePO15Amount = false
  public editInvoicePartner = false
  public editInvoicePartnerAmount = false
  public editStepstatus = false
  public editFileName: boolean[]
  public linkDeliveryNoteForm = false
  public deliveryNoteNumberToLink = ''
  public importShipmentForm = false
  public newMaterialForm = false
  public newMaterialTerm = ''
  public newMaterial: {
    item_nr: string,
    description: string,
    article_template_id: number,
    amount: number,
    status: string,
    sn: string
  }
  public newMaterialSelectedArticle: ArticleTemplate

  public materialStatusOptions = ['', 'Proposed', 'Ordered', 'Shipped', 'booked', 'wrongArticle', 'notInPackage']
  public statusOptions = [{ id: 'PLANNED', name: 'geplant' }, { id: 'DONE', name: 'erledigt' }, { id: 'REWORK', name: 'Nacharbeiten erforderlich' }]

  public newPartForm = false
  public newPartTerm = ''
  public newArticleTemplateTerm = ''
  public newPart: {
    sn_removed: string,
    article_id: number,
    error_description: string
  }
  public newPartSelectedArticle: Article
  public sliceCountArticles = 5

  public newSelectedArticleTemplate: ArticleTemplate

  public techicianSearchboxValue = ''
  public selectedTechnicianIds: number[] = []

  public searchArticles: Article[] = []

  public stepWarning = 'Achtung: Dieser Schritt ist nicht umkehrbar, er löst automatische Prozesse aus. Die App kann danach keine weiteren Daten mehr für diesen Auftrag übermitteln!'

  public shippingImportform: UntypedFormGroup
  public shippingImportStatus = 'new'
  public shippingImporterrorIds: string[] = []
  public shippingImporterrorText: string
  public shippingImporterrorLog: string

  public mtRejectionReasons = [
    { key: 'TSRC001', title: 'Falscher Dienstanbieter' },
    { key: 'TSRC002', title: 'Datenformat falsch' },
    { key: 'TSRC003', title: 'Pflichtinformationen fehlen' },
    { key: 'TSRC004', title: 'Datenqualität' },
    { key: 'TSRC005', title: 'Falsche Klassifikation (Abgeschlossen) - Techniker vor Ort bestätigt, dass der Artikel falsch klassifiziert wurde und er nicht der richtige Dienstleister ist.' },
    { key: 'TSRC006', title: 'Unzureichende Qualifikation' }
  ]
  public mtRejectionSelectedReason = null
  public mtRejectionText = 'Einsatz konnte nicht übernommen werden, für Rückfragen stehen wir gerne zur Verfügung'

  constructor(
    private operationService: OperationService,
    private articleService: ArticleService,
    private storeService: StoreService,
    private ticketService: TicketService,
    private workflowService: WorkflowService,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setEmptyMaterial()
    this.setEmptyPart()
    this.loadParts()
    this.loadFiles()
    this.loadArticleHandling()
    this.updateSelectedTechnicians()
    this.loadArticlesTemplates()

    this.shippingImportform = this.formBuilder.group({
      importfile: ['']
    })

    this.workflowService.getOperationWorkflowsPresent(this.operation.id).subscribe(
      (data) => {
        // console.log(data)
      },
      (err) => {
        console.error(err)
      },
    )

    this.setMtFields()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ticket'] && !changes['ticket'].isFirstChange()) {
      this.setMtFields();
    }
  }

  public setMtFields() {
    if (this.ticket) {
      this.mtRejectionSelectedReason = this.ticket.mt_rejection_code;
      this.mtRejectionText = this.ticket.mt_rejection_reason;
    }
  }

  public loadParts(selectLast = false) {
    this.selectedPart = null
    this.operationService.getParts(this.operation.id.toString()).subscribe(
      (data) => {
        this.parts = data
        this.editFileName = []

        this.parts.forEach((p, index) => {
          this.editFileName[index] = false
        })

        if (selectLast) {
          this.selectedPart = this.parts[this.parts.length - 1]
          console.log(this.parts, this.selectedPart)
        }
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public loadArticleHandling() {
    this.operationService.getArticleHandling(this.operation.id.toString()).subscribe(
      (data) => {
        this.articleHandling = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public loadArticlesTemplates() {
    this.articleService.getArticlesTemplatesByProject(this.ticket.project_id).subscribe(
      (data) => {
        this.articleTemplates = data

        console.log("articleTemplates", this.articleTemplates)
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public loadSearchArticles() {
    this.articleService.getArticlesByTemplate(this.newSelectedArticleTemplate.id).subscribe(
      (data) => {
        this.searchArticles = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public updateSelectedTechnicians() {
    this.selectedTechnicianIds = this.operation.technicians.map((technician) => technician.id)
  }

  public selectedTechnicians(): Technician[] {
    //  return this.technicians.filter(technician => this.selectedTechnicianIds.includes(technician.id))

    // I considered the approach above - a more elegant solution - but the correct order of ids was more importent
    // than the last bit of performance, so this map with an n² lookup was used. Its fast enough.
    return this.selectedTechnicianIds.map((techId) => this.technicians.filter(technician => technician.id === techId).pop()).filter((technician) => technician !== undefined)
  }

  public addTechnician(technicianId: number) {
    this.selectedTechnicianIds.push(technicianId)
  }

  public keysFor(hash): string[] {
    return Object.keys(hash).sort((a, b) => this.sortCleaned(a, b))
  }

  public sortCleaned(a, b): number {
    const cleanA = !!a ? this.cleanString(a) : ''
    const cleanB = !!b ? this.cleanString(b) : ''

    if (cleanA < cleanB) {
      return -1
    }
    if (cleanA > cleanB) {
      return 1
    }

    return 0
  }

  public cleanString(input): string {
    if (!input) {
      return ''
    }
    return input.trim().replace(/\s{2}/g, '')
  }

  public removeTechnician(technicianId: number) {
    this.selectedTechnicianIds = this.selectedTechnicianIds.filter((selectedTechId) => selectedTechId !== technicianId)
  }

  public openTechnicianForm() {
    this.updateSelectedTechnicians()
    this.editTechnicians = true
  }

  public countMaterials(materialArray): number {
    return materialArray.map((item) => item.amount).reduce((acc, count) => acc + count, 0)
  }

  public getSNForValueEntry(entry: MetaDataArticleOutput): string[] {
    if (entry === null || entry === undefined) {
      return []
    }
    let snObjects = Object.values(entry)
    return snObjects.map((item) => { return item.sn })
  }

  public selectMaterialArticleTemplate(articleTemplate: ArticleTemplate) {
    this.newMaterialSelectedArticle = articleTemplate
    this.newMaterial.article_template_id = articleTemplate.id

    return false
  }

  public unselectMaterialArticleTemplate() {
    this.newMaterialSelectedArticle = null
    this.newMaterial.article_template_id = null

    return false
  }

  public selectPartArticle(article: Article) {
    this.newPartSelectedArticle = article
    this.newPart.article_id = article.id

    this.resetArticleSliceCount()

    return false
  }

  public unselectPartArticle() {
    this.newPartSelectedArticle = null
    this.newPart.article_id = null
    this.resetArticleSliceCount()
    this.newPartTerm = ''

    return false
  }

  public selectPartArticleTemplate(articleTemplate: ArticleTemplate) {
    this.newSelectedArticleTemplate = articleTemplate
    this.resetArticleSliceCount()

    this.articleService.getArticlesByTemplate(this.newSelectedArticleTemplate.id).subscribe(
      (data) => {
        this.searchArticles = data
      },
      (err) => {
        console.error(err)
      },
    )

    return false
  }

  public unselectPartArticleTemplate() {
    this.newSelectedArticleTemplate = null
    this.newArticleTemplateTerm = ''
    this.newPartSelectedArticle = null
    this.newPart.article_id = null
    this.newPartTerm = ''
    this.resetArticleSliceCount()

    return false
  }

  public increaseArticleSliceCount() {
    this.sliceCountArticles += 5

    return false
  }

  public resetArticleSliceCount() {
    this.sliceCountArticles = 5
  }

  public technicianNames(): string {
    return this.operation.technicians.map((t) => t.full_name).join(', ')
  }

  public convertLinebreaks(text: string, replace: boolean = true): string {
    return replace ? text.replace(new RegExp('\n', 'g'), '<br />') : text
  }

  public toggleDescriptionLinebreaks() {
    this.descriptionLinebreaks = !this.descriptionLinebreaks
  }

  public toggleReportLinebreaks() {
    this.reportLinebreaks = !this.reportLinebreaks
  }

  public stepStatusEditable(): boolean {
    if (!this.operation.metadata) {
      return false
    }

    if (this.operation.metadata_done) {
      return false
    }

    if (this.status() !== 'erledigt' && this.status() !== 'Nacharbeiten erforderlich') {
      return false
    }

    return true
  }

  public toggleStepStatus() {
    if (!this.stepStatusEditable()) {
      return
    }

    this.editStepstatus = !this.editStepstatus
  }

  public onShippingFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      this.shippingImportform.get('importfile').setValue(file)
    }
  }

  public onShippingFileSubmit() {
    const formData = new FormData()
    formData.append('file', this.shippingImportform.get('importfile').value)
    this.shippingImportStatus = 'running'

    this.ticketService.ticketShipmentImport(formData).subscribe(
      (res) => {
        this.shippingImportStatus = 'done'
        this.shippingImporterrorIds = res.errors || []
        this.shippingImporterrorLog = res.log || ''

        this.reloadTicket.emit(true);
      },
      (err) => {
        console.error(err)
        this.shippingImportStatus = 'error'
        this.shippingImporterrorText = 'es ist etwas schief gegangen.'
      }
    )
  }

  public isTabSelected(tab: string): boolean {
    if (this.selectedTab) {
      return tab === this.selectedTab
    } else {
      return false
    }
  }

  public getMetadataImagePath(stepValue): string {
    return Utils.metadataImagePath(stepValue)
  }

  /**
   * status
   */
  public status(): string {
    if (!this.operation) {
      return ''
    }

    switch (this.operation.status) {
      case 'DONE': {
        return 'erledigt'
        break
      }
      case 'REWORK': {
        return 'Nacharbeiten erforderlich'
        break
      }
      case 'PLANNED': {
        return 'geplant'
        break
      }
      default: {
        return '???'
        break
      }
    }
  }

  public stepStatus(): string {
    if (!this.operation) {
      return ''
    }

    if (!this.operation.metadata) {
      return 'keine Schritte vorhanden'
    }

    if (this.operation.metadata_done) {
      return 'abgeschlossen'
    } else {
      return 'nicht abgeschlossen!'
    }
  }

  public formatArticleTemplate(articleTemplate: ArticleTemplate): string {
    return [articleTemplate.description, articleTemplate.product_category, articleTemplate.vendor, articleTemplate.id].join(', ')
  }

  public formatArticle(article: Article): string {
    return [article.rma, article.description, article.serial_number].join(', ')
  }

  public updateOperation(operationData: Operation) {
    this.operation = operationData
  }

  public signaturePath(base64Url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Url)
  }

  public saveUploadedFile(files: FileList) {
    this.operationService.saveUploadedFile(this.operation.id, files).subscribe(
      (data) => {
        console.log(data)
        this.loadFiles()
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public deleteFile(filename: string) {
    const doIt = window.confirm('Ja, Datei (' + filename + ') löschen.')

    if (doIt) {
      this.operationService.destroyFile(this.operation.id, filename).subscribe(
        (data) => {
          console.log(data)
          this.loadFiles()
        },
        (err) => {
          console.error(err)
        },
      )
    }
  }

  public loadFiles() {
    this.operationService.getFiles(this.operation.id).subscribe(
      (data) => {
        this.files = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public updateFilename(oldFilename: string, newFilename: EditEventType, index: number) {
    this.operationService.renameFile(this.operation.id, oldFilename, newFilename).subscribe(
      (data) => {
        console.log(data)
        this.editFileName[index] = false
        this.loadFiles()
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public saveMaterial() {
    this.ticketService.createMaterial(this.operation.ticket_id, this.newMaterial).subscribe(
      (data) => {
        console.log(data)
        this.setEmptyMaterial()
        this.ticket.material.push(data)
        this.newMaterialForm = false
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setEmptyMaterial() {
    this.newMaterial = {
      item_nr: '',
      description: '',
      article_template_id: null,
      amount: 1,
      status: '',
      sn: ''
    }
  }

  public savePart() {

    let part = {
      operation_id: this.operation.id,
      newsn: this.newPartSelectedArticle.serial_number,
      oldsn: this.newPart.sn_removed,
      description: this.newPart.error_description,
      article_id: this.newPartSelectedArticle.id,
      rma: this.newPartSelectedArticle.rma
    }

    this.newPartForm = false
    this.operationService.createPart(part).subscribe(
      (_) => {
        this.loadParts()
        this.setEmptyPart()
        this.reloadDeliveryNotes()
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setEmptyPart() {
    this.newPart = {
      article_id: null,
      error_description: '',
      sn_removed: ''
    }
    this.unselectPartArticle()
  }

  public deletePart() {
    const doIt = window.confirm('Diesen Eintrag wirklich löschen?')

    if (doIt) {
      this.operationService.destroyPart(this.selectedPart).subscribe(
        (_) => {
          this.loadParts()
        },
        (err) => {
          console.error(err)
        },
      )
    }
  }

  public navigateNewDN() {
    const link = ['/a/lieferscheine/neu?ticket_id=', this.ticket.id].join('')
    window.open(link, '_blank')
  }

  public linkDeliveryNote() {
    this.ticketService.linkDeliveryNote(this.ticket.id, this.deliveryNoteNumberToLink).subscribe(
      (data) => {
        this.linkDeliveryNoteForm = false
        this.ticket.delivery_notes = data

        this.cdr.detectChanges()
      },
      (error) => {
        alert('Dieser Lieferschein konnte nicht gespeichert werden.')
      }
    )
  }

  public reloadDeliveryNotes() {
    // waiting here until old hawk has a chance to update. The method will return
    //  before timeout ends, this is intended.
    setTimeout(() => {
      this.ticketService.getDeliveryNotes(this.ticket.id).subscribe(
        (data) => {
          this.ticket.delivery_notes = data
        },
        (error) => {
          alert('Fehler')
        }
      )
    }, 5000)
  }

  public deleteDN(deliveryNoteId: number) {
    const doIt = window.confirm('Ja, Verlinkung wirklich entfernen (Info: Lieferschein bleibt wird nicht gelöscht)')

    if (doIt) {
      this.ticketService.unlinkDeliveryNote(this.ticket.id, deliveryNoteId).subscribe(
        (data) => {
          this.linkDeliveryNoteForm = false
          this.ticket.delivery_notes = data
        },
        (error) => {
          alert('Dieser Lieferschein konnte nicht gespeichert werden.')
        }
      )
    }
  }

  public editPart(part: Part) {
    console.log(part)
    this.selectedPart = part
  }

  public updatePart() {
    this.operationService.savePart(this.selectedPart).subscribe(
      (_) => {
        this.loadParts()
      },
      (error) => {
        alert('Konnte teil nicht speichern.' + error)
      }
    )
  }

  public cancelPart() {
    this.loadParts()
  }

  public saveDescription(event: EditEventType) {
    this.metaUpdate('description', event)
    this.editDescription = false
  }

  public saveTechnicianReport(event: EditEventType) {
    this.metaUpdate('report', event)
    this.editTechnicianReport = false
  }

  public saveStatus(event: EditEventType) {
    this.metaUpdate('status', event)
    this.editStatus = false
  }

  public saveTravelStart(event: EditEventType) {
    this.metaUpdate('date_travel_start', event)
    this.editTravelStart = false
  }

  public saveArrival(event: EditEventType) {
    this.metaUpdate('date_on_site', event)
    this.editArrival = false
  }

  public saveRepaired(event: EditEventType) {
    this.metaUpdate('date_repaired', event)
    this.editRepaired = false
  }

  public saveFinished(event: EditEventType) {
    this.metaUpdate('date_finished', event)
    this.editFinished = false
  }

  public saveDistance(event: EditEventType) {
    this.metaUpdate('distance', event)
    this.editDistance = false
  }

  public saveOperationDate(event: EditEventType) {
    this.metaUpdate('operation_date', event)
    this.editOperationDate = false
  }

  public saveInvoiceCustomer(event: EditEventType) {
    this.metaUpdate('invoice', event)
    this.editInvoiceCustomer = false
  }

  public saveInvoiceAmount(event: EditEventType) {
    this.metaUpdate('invoice_amount', event)
    this.editInvoiceAmount = false
  }

  public saveInvoicePOAmount(event: EditEventType) {
    this.metaUpdate('invoice_po_order_amount', event)
    this.editInvoicePOAmount = false
  }

  public saveInvoicePOXAmount(event: EditEventType) {
    this.metaUpdate('invoice_pox_order_amount', event)
    this.editInvoicePOXAmount = false
  }

  public saveInvoicePO15Amount(event: EditEventType) {
    this.metaUpdate('invoice_po15_order_amount', event)
    this.editInvoicePO15Amount = false
  }

  public saveInvoicePartner(event: EditEventType) {
    this.metaUpdate('invoice_partner', event)
    this.editInvoicePartner = false
  }

  public saveInvoicePartnerAmount(event: EditEventType) {
    this.metaUpdate('invoice_partner_amount', event)
    this.editInvoicePartnerAmount = false
  }

  public saveTechnicians() {
    this.metaUpdate('technician_ids', this.selectedTechnicianIds)
    this.editTechnicians = false
  }

  public markStepDone() {
    const doIt = window.confirm(this.stepWarning)

    if (doIt) {
      this.operationService.markStepsDone(this.operation.id).subscribe(
        (data) => {
          window.location.reload()
        },
        (error) => {
          alert('Konnte Änderung nicht durchführen!')
        }
      )
    }
  }

  private metaUpdate(key, value) {
    const changeData = {}
    changeData[key] = value

    this.operationService.updateOperation(this.operation.id.toString(), { operation: changeData }).subscribe(
      (data) => {
        this.updateOperation(data)
      },
      (error) => { }
    )
  }

  public articleInstallationFieldType(value): 'sn_list' | 'sn' | 'no_sn' | 'noSn' | 'unknown' {
    if (value instanceof Array) {
      return 'sn_list'
    }

    if (value && value.sn) {
      return 'sn'
    }

    if (value && value.no_sn) {
      return 'no_sn'
    }

    if (value && value.noSn) {
      return 'noSn'
    }

    return 'unknown'
  }

  public transferStep(step: OperationMetadata) {
    this.selectStep.emit(step)
  }

  public sendRejection() {
    this.ticketService.updateToRejectTicket(this.ticket.id.toString(), { mt_rejection_code: this.mtRejectionSelectedReason, mt_rejection_reason: this.mtRejectionText }).subscribe(
      (_) => {
        this.reloadTicket.emit(true)
      },
      (error) => {
        alert('Fehler beim Speichern der Ablehnung.')
      }
    )
  }
}