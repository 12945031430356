<div class="operationSubpage">
  <section>
    <h3 (click)="editDescription = !editDescription">Beauftragung</h3>
    <p class="description" [innerHtml]="convertLinebreaks(operation.description, descriptionLinebreaks)"
      *ngIf="!editDescription" (click)="toggleDescriptionLinebreaks()"></p>

    <p *ngIf="editDescription">
      <hawk-shared-edit-input [textValue]="operation.description" [modus]="'textarea'"
        (saveClicked)="saveDescription($event)" (cancelClicked)="editDescription = false"></hawk-shared-edit-input>
    </p>
    <!--  <ul class="linklist">
        <li><a href="#">Vorlage</a></li>
        <li><a href="#">Fehlertext übernehmen</a></li>
      </ul>-->
  </section>

  <section class="tabs">
    <div class="tablist" role="tablist">
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('overview')"
        (click)="selectedTab = 'overview'">Rückmeldung
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('steps')" (click)="selectedTab = 'steps'">
        Schritte
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('mt_result')" (click)="selectedTab = 'mt_result'"  *ngIf="ticket.is_mt">
        MT Ergebnis
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('material')"
        (click)="selectedTab = 'material'">Material
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('delivery_notes')"
        (click)="selectedTab = 'delivery_notes'">Lieferscheine
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('billing')" (click)="selectedTab = 'billing'"
        *ngIf="isBookkeeper">
        Abrechnung
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('files')" (click)="selectedTab = 'files'">
        Dateien
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('extras')" (click)="selectedTab = 'extras'">
        Extras
      </button>
      <button role="tab" class="tab" [attr.aria-selected]="isTabSelected('mt_bonus')" (click)="selectedTab = 'mt_bonus'" *ngIf="ticket.is_mt">
        MT Spezial
      </button>
    </div>
    <ng-container *ngIf="selectedTab == 'overview'">
      <div class="columns">
        <section>
          <h3>Details</h3>
          <table class="base"
            [class.operationDone]="(operation.status == 'DONE' || operation.status == 'REWORK') && (!operation.metadata || operation.metadata_done)"
            [class.operationProblem]="(operation.status == 'DONE' || operation.status == 'REWORK') && operation.metadata && !operation.metadata_done">

            <tr>
              <th>ID</th>
              <td>{{operation?.id}}</td>
            </tr>
            <tr>
              <th class="editable" (click)="editOperationDate = !editOperationDate">Plandatum</th>
              <td *ngIf="!editOperationDate">{{operation.operation_date | date: 'dd.MM.yyyy HH:mm'}}</td>
              <td *ngIf="editOperationDate">
                <hawk-shared-edit-input [textValue]="operation.operation_date" [modus]="'date'"
                  (saveClicked)="saveOperationDate($event)" (cancelClicked)="editOperationDate = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="openTechnicianForm()">Techniker</th>
              <td>
                <ng-container *ngIf="!editTechnicians">
                  <ng-container *ngFor="let tech of operation.technicians ">
                    <a routerLink="/benutzer/{{tech.id}}" target="_blank">{{tech.full_name}}</a> (<a
                      href="tel:{{tech.phone1}}">{{tech.phone1}}</a>)
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="editTechnicians">
                  <p>
                    <b style="padding-right: 10px;">bisher ausgewählt</b>
                    <button class="actionButton" (click)="saveTechnicians()">
                      <fa-icon [icon]="['far','save']"></fa-icon>
                    </button>
                    <button class="actionButton" (click)="editTechnicians = false">
                      <fa-icon [icon]="['far','times']"></fa-icon>
                    </button>
                    <br>
                    <ng-container *ngFor="let technician of selectedTechnicians() ">
                      {{technician.name}} <small *ngIf="technician.company">({{technician.company}})</small> <a href=""
                        class="technicianAction" (click)="removeTechnician(technician.id);false">
                        <fa-icon [icon]="['fas','trash']"></fa-icon>
                      </a> <br>
                    </ng-container>
                  </p>

                  <p class="techicianSearchbox">
                    <b>Techniker suchen</b><br>
                    <input type="text" [(ngModel)]="techicianSearchboxValue" placeholder="Technikername eingeben"><br>
                    <ng-container
                      *ngFor="let technician of technicians | technicianFilter:techicianSearchboxValue | slice:0:5">
                      {{technician.name}} <small *ngIf="technician.company">({{technician.company}})</small> <a href=""
                        class="technicianAction" (click)="addTechnician(technician.id);false">
                        <fa-icon [icon]="['fas','user-plus']"></fa-icon>
                      </a> <br>
                    </ng-container>
                  </p>
                </ng-container>

              </td>
            </tr>

            <tr class="seperator">
              <th class="editable" (click)="editStatus = !editStatus">Status</th>
              <td *ngIf="!editStatus">{{status()}}</td>
              <td *ngIf="editStatus">
                <hawk-shared-edit-input [textValue]="operation.status" [selectOptions]="statusOptions"
                  [modus]="'select'" (saveClicked)="saveStatus($event)" (cancelClicked)="editStatus = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
            <tr *ngIf="operation.metadata">
              <th [class.editable]="stepStatusEditable()" (click)="toggleStepStatus()">Schrittstatus</th>
              <td *ngIf="!editStepstatus">{{stepStatus()}}</td>
              <td *ngIf="editStepstatus">
                <button (click)="markStepDone()">Schritte abschließen!</button><br>
                <small>{{stepWarning}}</small>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editTravelStart = !editTravelStart">Reisebeginn</th>
              <td *ngIf="!editTravelStart">{{operation.date_travel_start | date: 'dd.MM.yyyy HH:mm'}}</td>
              <td *ngIf="editTravelStart">
                <hawk-shared-edit-input [dateValue]="operation.date_travel_start" [modus]="'date'"
                  (saveClicked)="saveTravelStart($event)" (cancelClicked)="editTravelStart = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editArrival = !editArrival">Ankunft</th>
              <td *ngIf="!editArrival">{{operation.date_on_site | date: 'dd.MM.yyyy HH:mm'}}</td>
              <td *ngIf="editArrival">
                <hawk-shared-edit-input [dateValue]="operation.date_on_site" [modus]="'date'"
                  (saveClicked)="saveArrival($event)" (cancelClicked)="editArrival = false"></hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editRepaired = !editRepaired">Repaired</th>
              <td *ngIf="!editRepaired">{{operation.date_repaired | date: 'dd.MM.yyyy HH:mm'}}</td>
              <td *ngIf="editRepaired">
                <hawk-shared-edit-input [dateValue]="operation.date_repaired" [modus]="'date'"
                  (saveClicked)="saveRepaired($event)" (cancelClicked)="editRepaired = false"></hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editFinished = !editFinished">Finished</th>
              <td *ngIf="!editFinished">{{operation.date_finished | date: 'dd.MM.yyyy HH:mm'}}</td>
              <td *ngIf="editFinished">
                <hawk-shared-edit-input [dateValue]="operation.date_finished" [modus]="'date'"
                  (saveClicked)="saveFinished($event)" (cancelClicked)="editFinished = false"></hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editDistance = !editDistance">Entfernung</th>
              <td>
                <span *ngIf="operation.travel_distance && operation.travel_duration">{{operation.travel_distance |
                  number:'1.0-0'}}
                  km, {{operation.travel_duration | number:'1.0-0'}} min, </span>Luft: {{operation.calculated_distance}}
                km<span *ngIf="operation.distance && operation.distance > 0">, Rückmeldung: {{operation.distance |
                  number:'1.0-0'}}
                  km</span>
                <span *ngIf="editDistance">
                  <hawk-shared-edit-input [numberValue]="operation.distance" [modus]="'number'"
                    (saveClicked)="saveDistance($event)" (cancelClicked)="editDistance = false">
                  </hawk-shared-edit-input>
                </span>
              </td>
            </tr>
            <tr>
              <th>GPS</th>
              <td>not implemented yet
              </td>
            </tr>
          </table>
        </section>
        <section>
          <div class="columns" *ngIf="newPartForm">
            <section>
              <h3 (click)="newPartForm = false">Neues Teil auswählen
                <fa-icon [icon]="['far','times']"></fa-icon>
              </h3>
              <table class="base">
                <tr>
                  <td>Artikeltyp</td>
                  <td>
                    <span *ngIf="!newSelectedArticleTemplate">keiner ausgewählt</span>
                    <span *ngIf="newSelectedArticleTemplate">{{formatArticleTemplate(newSelectedArticleTemplate)}}
                      <a href="" class="technicianAction" (click)="unselectPartArticleTemplate()">
                        <fa-icon [icon]="['fas','times']"></fa-icon>
                      </a></span><br>
                    <ng-container *ngIf="!newSelectedArticleTemplate">
                      <input type="text" [(ngModel)]="newArticleTemplateTerm" placeholder="Suchbegriff eingeben"><br>
                      <small><b>zum Suchbegriff passende Artikeltypen:</b></small><br>
                      <ng-container
                        *ngFor="let articleTemplate of articleTemplates | articleTemplateFilter:newArticleTemplateTerm | slice:0:20">
                        <small
                          (click)="selectPartArticleTemplate(articleTemplate)">{{formatArticleTemplate(articleTemplate)}}</small>
                        <a href="" class="technicianAction" (click)="selectPartArticleTemplate(articleTemplate)">
                          <fa-icon [icon]="['fas','check-square']"></fa-icon>
                        </a> <br>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
                <ng-container *ngIf="newSelectedArticleTemplate">
                  <tr>
                    <td>Artikel</td>
                    <td>
                      <span *ngIf="!newPartSelectedArticle">keiner ausgewählt</span>
                      <span *ngIf="newPartSelectedArticle">{{formatArticle(newPartSelectedArticle)}} <a href=""
                          class="technicianAction" (click)="unselectPartArticle()">
                          <fa-icon [icon]="['fas','times']"></fa-icon>
                        </a></span><br>
                      <ng-container *ngIf="!newPartSelectedArticle">
                        <input type="text" [(ngModel)]="newPartTerm" placeholder="Suchbegriff eingeben"><br>
                        <small><b>zum Suchbegriff passende Artikel:</b></small><br>
                        <ng-container
                          *ngFor="let article of searchArticles | articleFilter:newPartTerm | slice:0:sliceCountArticles">
                          <small (click)="selectPartArticle(article)">{{formatArticle(article)}}</small> <a href=""
                            class="technicianAction" (click)="selectPartArticle(article)">
                            <fa-icon [icon]="['fas','check-square']"></fa-icon>
                          </a> <br>
                        </ng-container>
                        <a href="" (click)="increaseArticleSliceCount()"><small>(mehr laden)</small></a>
                      </ng-container>
                    </td>
                  </tr>
                  <ng-container *ngIf="newPartSelectedArticle">
                    <tr>
                      <td>SN ausgebaut</td>
                      <td><input type="text" [(ngModel)]="newPart.sn_removed"></td>
                    </tr>
                    <tr>
                      <td>Fehlerbeschreibung</td>
                      <td><input type="text" [(ngModel)]="newPart.error_description"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button class="actionButton" (click)="savePart()"
                          [disabled]="newPart.article_id === null">Speichern</button>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
            </section>
          </div>

          <h3>
            Gewechselte Teile
            <fa-icon [icon]="['far','plus']" (click)="newPartForm = true"></fa-icon>
          </h3>

          <table class="base" *ngIf="parts">
            <thead>
              <tr>
                <th>RMA</th>
                <th>Typ</th>
                <th>SN eingebaut</th>
                <th>SN ausgebaut</th>
                <th>Fehlerbeschreibung</th>
              </tr>
            </thead>
            <ng-container *ngFor="let part of parts">
              <tr *ngIf="part !== selectedPart">
                <td *ngIf="part.article"><a href="/warehouse/article/{{part.article?.id}}/show"
                    target="_blank">{{part.rma}}</a>
                </td>
                <td *ngIf="!part.article">{{part.rma}}
                </td>
                <td>{{part.article?.description}}</td>
                <td>{{part.newsn}}</td>
                <td>{{part.oldsn}}</td>
                <td>{{part.description}}</td>
              </tr>
            </ng-container>
          </table>
        </section>
      </div>
      <section>
        <h3 (click)="editTechnicianReport = !editTechnicianReport">Technikerreport</h3>
        <p [innerHtml]="convertLinebreaks(operation.report, reportLinebreaks)"
          *ngIf="operation.report && !editTechnicianReport" (click)="toggleReportLinebreaks()"></p>

        <p *ngIf="editTechnicianReport">
          <hawk-shared-edit-input [textValue]="operation.report" [modus]="'textarea'"
            (saveClicked)="saveTechnicianReport($event)" (cancelClicked)="editTechnicianReport = false">
          </hawk-shared-edit-input>
        </p>
      </section>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'steps'">
      <ng-container *ngFor="let md of operation.metadata">
        <section>
          <h3>{{ md.name }}</h3>
          <table class="base" *ngIf="parts">
            <ng-container *ngFor="let step of md.steps">
              <tr *ngIf="false && step.value">
                <td></td>
                <td>
                  <pre>{{step | json}}</pre>
                </td>
              </tr>
              <!-- Fixes a problem that empty (and possibly non necessary steps) were rendered -->
              <tr *ngIf="step.value">
                <td style="width:350px">{{step.name}}</td>
                <ng-container [ngSwitch]="step.stepType">
                  <td *ngSwitchCase="'yesNo'">{{step.value == true ? 'Ja' : 'nein'}}</td>
                  <td *ngSwitchCase="'timestamp'">{{step.value | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
                  <td *ngSwitchCase="'articleInput'">
                    Techniker will {{countMaterials(step.value)}} Teile erfassen<br><br>

                    erfasst wurden:
                    <ng-container *ngFor="let v of step.value">
                      <br>
                      {{v.amount}}x: {{v.selected?.title || 'ohne Bezeichnung'}}, SNs: {{
                      getSNForValueEntry(v.serialNumbers).join(', ') }} (SN erfasst:
                      {{getSNForValueEntry(v.serialNumbers).length}}, nicht erfasst: {{v.amount -
                      getSNForValueEntry(v.serialNumbers).length}})

                    </ng-container>
                  </td>
                  <td *ngSwitchCase="'articleRemoval'">{{countMaterials(step.value)}} Teil(e) ausgebaut</td>
                  <td *ngSwitchCase="'articleInstallation'">
                    <ng-container [ngSwitch]="articleInstallationFieldType(step.value)">
                      <ng-container *ngSwitchCase="'sn_list'">
                        <ng-container *ngFor="let sn of step.value">
                          <hawk-operation-subpage-step-article [articleId]="sn"></hawk-operation-subpage-step-article>
                          <br />
                        </ng-container>
                      </ng-container>
                      <ng-container *ngSwitchCase="'sn'">
                        {{step?.value?.sn?.description}}<br>
                        <span>SN: </span>{{step?.value?.sn?.serialNumber?.sn}}<br>
                      </ng-container>
                      <ng-container *ngSwitchCase="'no_sn'">
                        {{step?.value?.no_sn?.amount || 0}}x {{step?.value?.no_sn?.description}}
                      </ng-container>
                      <ng-container *ngSwitchCase="'noSn'">
                        {{step?.value?.noSn?.amount || 0}}x {{step?.value?.noSn?.description}}
                      </ng-container>
                    </ng-container>
                  </td>
                  <td *ngSwitchCase="'photo'">
                    <a href="{{getMetadataImagePath(step.value)}}" target="_blank"><img
                        [attr.src]="getMetadataImagePath(step.value)" class="metaImage" /></a>
                  </td>
                  <td *ngSwitchCase="'signature'">
                    <img [attr.src]="signaturePath(step.value)" />
                  </td>
                  <td *ngSwitchDefault>{{step.value}}</td>
                </ng-container>
              </tr>
            </ng-container>
          </table>
        </section>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedTab == 'mt_result'">
      <!-- FIXME: PUT COMPONENT HERE -->
      <app-mt-result [ticketId]="ticket.id"></app-mt-result>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'material'">
      <div class="columns" *ngIf="newMaterialForm">
        <section>
          <h3 (click)="newMaterialForm = false">Neues Material hinzufügen
            <fa-icon [icon]="['far','times']"></fa-icon>
          </h3>
          <table class="base">
            <tr *ngShow="false">
              <td>Artikelnummer</td>
              <td><input type="text" [(ngModel)]="newMaterial.item_nr"></td>
            </tr>
            <tr>
              <td>Typ</td>
              <td>
                <span *ngIf="!newMaterialSelectedArticle">keiner ausgewählt</span>
                <span *ngIf="newMaterialSelectedArticle">{{formatArticleTemplate(newMaterialSelectedArticle)}} <a
                    href="" class="technicianAction" (click)="unselectMaterialArticleTemplate()">
                    <fa-icon [icon]="['fas','times']"></fa-icon>
                  </a></span><br>
                <ng-container *ngIf="!newMaterialSelectedArticle">
                  <input type="text" [(ngModel)]="newMaterialTerm" placeholder="Suchbegriff eingeben"><br>
                  <small><b>zum Suchbegriff passende Artikel:</b></small><br>
                  <ng-container
                    *ngFor="let articleTemplate of articleTemplates | articleTemplateFilter:newMaterialTerm | slice:0:5">
                    <small>{{formatArticleTemplate(articleTemplate)}}</small> <a href="" class="technicianAction"
                      (click)="selectMaterialArticleTemplate(articleTemplate)">
                      <fa-icon [icon]="['fas','check-square']"></fa-icon>
                    </a> <br>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
            <tr *ngShow="false">
              <td>Beschreibung</td>
              <td><input type="text" [(ngModel)]="newMaterial.description"></td>
            </tr>
            <tr>
              <td>Anzahl</td>
              <td><input type="number" [(ngModel)]="newMaterial.amount"></td>
            </tr>
            <tr *ngShow="false">
              <td>Status</td>
              <td>
                <select name="status" id="status" [(ngModel)]="newMaterial.status">
                  <option *ngFor="let status of materialStatusOptions" [value]="status"
                    [selected]="status === newMaterial.status">{{status}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Seriennummer</td>
              <td><input type="text" [(ngModel)]="newMaterial.sn"></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button class="actionButton" (click)="saveMaterial()">Speichern</button>
              </td>
            </tr>
          </table>
        </section>
      </div>
      <div class="columns">
        <section>
          <h3>Benötigtes Material Techniker
            <fa-icon [icon]="['far','plus']" (click)="newMaterialForm = true"></fa-icon>
          </h3>
          <table class="base">
            <tr>
              <th>Typ</th>
              <th>Anzahl</th>
              <th>Status</th>
              <th>Beschreibung</th>
              <th>SN</th>
              <th>ArtNr</th>
            </tr>
            <tr *ngFor="let material of ticket.material">
              <td>{{ material.title }}</td>
              <td>{{ material.amount }}</td>
              <td>{{ material.status }}</td>
              <td>{{ material.description }}</td>
              <td>{{ material.sn }}</td>
              <td>{{ material.item_nr }}</td>
            </tr>
          </table>
        </section>
      </div>
      <div class="columns">
        <section>
          <h3>Materialeingang</h3>
          <table class="base">
            <tr>
              <th>Typ</th>
              <th>Anzahl</th>
              <th>Seriennummern</th>
            </tr>
            <tr *ngFor="let article of []">
              <td>{{ article.title }}</td>
              <td>{{ article.amount }}</td>
              <td><span *ngFor="let sn of article.serial_numbers">{{ sn }}</span></td>
            </tr>
          </table>
        </section>
        <section>
        </section>
      </div>
      <div class="columns" *ngIf="articleSummary">
        <section *ngIf="articleSummary.article_input_compact">
          <h3>Zusammenfassung Material Eingang (Ticket)</h3>
          <table class="base">
            <tr>
              <th>Anzahl</th>
              <th>Typ</th>
            </tr>
            <tr *ngFor="let article of keysFor(articleSummary.article_input_compact)">
              <td>{{articleSummary.article_input_compact[article]?.length}}x</td>
              <td>{{ article }}</td>
            </tr>
          </table>
        </section>
        <section *ngIf="articleSummary.article_installation_compact">
          <h3>Zusammenfassung Material Installation (Ticket)</h3>
          <table class="base">
            <tr>
              <th>Anzahl</th>
              <th>Typ</th>
            </tr>
            <tr *ngFor="let article of keysFor(articleSummary.article_installation_compact)">
              <td>{{articleSummary.article_installation_compact[article]?.length}}x</td>
              <td>{{ cleanString(article) }}</td>
            </tr>
          </table>
        </section>
        <section *ngIf="articleSummary.article_removal_compact">
          <h3>Zusammenfassung Material Ausbau (Ticket)</h3>
          <table class="base">
            <tr>
              <th>Anzahl</th>
              <th>Typ</th>
            </tr>
            <tr *ngFor="let article of keysFor(articleSummary.article_removal_compact)">
              <td>{{articleSummary.article_removal_compact[article]?.length}}x</td>
              <td>{{ article }}</td>
            </tr>
          </table>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'delivery_notes'">
      <div class="columns" *ngIf="linkDeliveryNoteForm">
        <section>
          <h3 (click)="linkDeliveryNoteForm = false">Lieferschein verlinken
            <fa-icon [icon]="['far','times']"></fa-icon>
          </h3>
          <table class="base">
            <tr>
              <td>Lieferscheinnummer angeben (z.B. LS-2021-00815)</td>
              <td><input type="text" [(ngModel)]="deliveryNoteNumberToLink"></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button class="actionButton" (click)="linkDeliveryNote()">Speichern</button>
              </td>
            </tr>
          </table>
        </section>
      </div>
      <div class="columns">
        <section>
          <h3>Lieferscheine
            <button class="boxButton" (click)="linkDeliveryNoteForm = true">
              <fa-icon [icon]="['far','link']"></fa-icon>
              Lieferschein verlinken
            </button>
            <button class="boxButton" (click)="navigateNewDN()">
              <fa-icon [icon]="['far','plus']"></fa-icon>
              Neuer Lieferschein
            </button>
          </h3>
          <table class="base">
            <tr *ngFor="let dn of ticket.delivery_notes">
              <td>                
                <a [routerLink]="['/', 'lieferscheine', dn.id]" target="_blank">
                  <fa-icon [icon]="['far','truck']"></fa-icon>
                  {{dn.number}}
                </a>
              </td>
              <td>
                <ng-container [ngSwitch]="dn.note_type">
                  <span class='shippingLabel shippingLabel-info' *ngSwitchCase="'incoming'">Entgegengenommen</span>
                  <span class='shippingLabel shippingLabel-info' *ngSwitchCase="'installed'">Eingebaut</span>
                  <span class='shippingLabel shippingLabel-info' *ngSwitchCase="'removed'">Ausgebaut</span>
                  <span class='shippingLabel shippingLabel-info' *ngSwitchCase="'returned'">Zurückgeschickt</span>
                </ng-container>
              </td>
              <td>
                <ng-container [ngSwitch]="dn.shipcloud_tracking_status">
                  <span class='shippingLabel shippingLabel-default' *ngSwitchCase="'UNKNOWN'">Unbekannt</span>
                  <span class='shippingLabel shippingLabel-warning' *ngSwitchCase="'TRANSIT'">Unterwegs</span>
                  <span class='shippingLabel shippingLabel-warning' *ngSwitchCase="'OUT_FOR_DELIVERY'">In
                    Zustellung</span>
                  <span class='shippingLabel shippingLabel-success' *ngSwitchCase="'DELIVERED'">Zugestellt</span>
                </ng-container>
                <a href="/warehouse/deliveryNote/{{dn.id}}/checkShipcloudTrackingStatus" target="_blank"
                  (click)="reloadDeliveryNotes()" style="margin-left: 5px">
                  <fa-icon [icon]="['far','sync-alt']"></fa-icon>
                </a>
              </td>
              <td>
                <a href="{{dn.shipcloud_tracking_url}}" target="_blank" style="margin-left: 5px"
                  *ngIf="dn.shipcloud_tracking_url">
                  Tracking
                </a>
              </td>
              <td>
                <button (click)="deleteDN(dn.id)">
                  <fa-icon [icon]="['far','trash']"></fa-icon>
                </button>
              </td>
            </tr>
          </table>
        </section>
        <section *ngIf="importShipmentForm">
          <h3 (click)="importShipmentForm = false">externe Sendungen hochladen
            <fa-icon [icon]="['far','times']"></fa-icon>
          </h3>

          <p>

          </p>
          <p>
            gültige Felder: <br>
            - ticket_id (z.B: MI-20-044100)<br>
            - carrier (z.B. UPS, DHL)<br>
            - sendungsnummer (z.B. 1Z6932186861573064 )<br>
            - versandatum (z.B. 14.08.20)<br>
            - empfangsplz (z.B. 24251)
            <br>
            <br><br>

            Upload im <b>csv</b>-Format
          </p>

          <form [formGroup]="shippingImportform" (ngSubmit)="onShippingFileSubmit()">

            <input type="file" name="importfile" (change)="onShippingFileChange($event)" />
            <button type="submit">Upload</button>

          </form>

          <div *ngIf="shippingImportStatus == 'running'">

            <h3>Import läuft, bitte warten!</h3>
          </div>

          <div class="card-body" *ngIf="shippingImportStatus == 'done'">

            <h3>Abgeschlossen!</h3>
            <p>Anzahl Fehler: {{shippingImporterrorIds.length}}</p>
            <p>Fehlerhafte ids: {{shippingImporterrorIds | json}}</p>
            <p>log: {{shippingImporterrorLog | json}}</p>
          </div>

          <div class="card-body" *ngIf="shippingImportStatus == 'error'">

            <h3>Fehler!</h3>
          </div>

          <div>
            <button (click)="importShipmentForm = false">Zur Übersicht</button>
          </div>
        </section>
        <section *ngIf="!importShipmentForm">
          <h3>externe Sendungen
            <button class="boxButton" (click)="importShipmentForm = true">
              <fa-icon [icon]="['far','plus']"></fa-icon>
              Neuen Import starten
            </button>
          </h3>
          <table class="base">
            <tr *ngFor="let shipment of ticket.ticket_shipments">
              <td>
                <fa-icon [icon]="['far','question']" *ngIf="!shipment.api_provider"></fa-icon>
                <ng-container [ngSwitch]="shipment.last_status" *ngIf="shipment.api_provider">
                  <fa-icon [icon]="['far','question']" *ngSwitchCase="'unknown'"></fa-icon>
                  <fa-icon [icon]="['far','check']" *ngSwitchCase="'delivered'"></fa-icon>
                  <fa-icon [icon]="['far','undo']" *ngSwitchCase="'return_to_sender'"></fa-icon>
                </ng-container>
                {{shipment.carrier}}: {{shipment.carrier_tracking_no}}
              </td>
              <td>
                {{shipment.last_update | date: 'dd.MM.yyyy HH:mm'}}
              </td>
            </tr>
          </table>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'billing'">
      <div class="columns">
        <section>
          <h3>An Kunden</h3>
          <table class="base">
            <tr>
              <th class="editable" (click)="editInvoiceCustomer = !editInvoiceCustomer">Rechnungsnummer</th>
              <td *ngIf="!editInvoiceCustomer">{{operation.invoice}}</td>
              <td *ngIf="editInvoiceCustomer">
                <hawk-shared-edit-input [textValue]="operation.invoice" (saveClicked)="saveInvoiceCustomer($event)"
                  (cancelClicked)="editInvoiceCustomer = false"></hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editInvoiceAmount = !editInvoiceAmount">Betrag</th>
              <td *ngIf="!editInvoiceAmount">{{operation.invoice_amount}} <span
                  *ngIf="operation.invoice_amount">€</span></td>
              <td *ngIf="editInvoiceAmount">
                <hawk-shared-edit-input [numberValue]="operation.invoice_amount" [modus]="'number'"
                  (saveClicked)="saveInvoiceAmount($event)" (cancelClicked)="editInvoiceAmount = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
          </table>
        </section>
        <section>
          <h3>Von Parnter</h3>
          <table class="base">
            <tr>
              <th class="editable" (click)="editInvoicePartner = !editInvoicePartner">Rechnungsnummer</th>
              <td *ngIf="!editInvoicePartner">{{operation.invoice_partner}}</td>
              <td *ngIf="editInvoicePartner">
                <hawk-shared-edit-input [textValue]="operation.invoice_partner"
                  (saveClicked)="saveInvoicePartner($event)" (cancelClicked)="editInvoicePartner = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
            <tr>
              <th class="editable" (click)="editInvoicePartnerAmount = !editInvoicePartnerAmount">Betrag</th>
              <td *ngIf="!editInvoicePartnerAmount">{{operation.invoice_partner_amount}} <span
                  *ngIf="operation.invoice_partner_amount">€</span></td>
              <td *ngIf="editInvoicePartnerAmount">
                <hawk-shared-edit-input [numberValue]="operation.invoice_partner_amount" [modus]="'number'"
                  (saveClicked)="saveInvoicePartnerAmount($event)" (cancelClicked)="editInvoicePartnerAmount = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
          </table>
        </section>
        <section>
          <h3>PO / Bestellung</h3>
          <table class="base">
            <tr>
              <th class="editable" (click)="editInvoicePOAmount = !editInvoicePOAmount">Betrag</th>
              <td *ngIf="!editInvoicePOAmount">{{operation.invoice_po_order_amount}} <span
                  *ngIf="operation.invoice_po_order_amount">€</span></td>
              <td *ngIf="editInvoicePOAmount">
                <hawk-shared-edit-input [numberValue]="operation.invoice_po_order_amount" [modus]="'number'"
                  (saveClicked)="saveInvoicePOAmount($event)" (cancelClicked)="editInvoicePOAmount = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
          </table>
        </section>

        <section>
          <h3>POX</h3>
          <table class="base">
            <tr>
              <th class="editable" (click)="editInvoicePOXAmount = !editInvoicePOXAmount">Betrag</th>
              <td *ngIf="!editInvoicePOXAmount">{{operation.invoice_pox_order_amount}} <span
                  *ngIf="operation.invoice_pox_order_amount">€</span></td>
              <td *ngIf="editInvoicePOXAmount">
                <hawk-shared-edit-input [numberValue]="operation.invoice_pox_order_amount" [modus]="'number'"
                  (saveClicked)="saveInvoicePOXAmount($event)" (cancelClicked)="editInvoicePOXAmount = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
          </table>
        </section>
        <section>
          <h3>PO15</h3>
          <table class="base">
            <tr>
              <th class="editable" (click)="editInvoicePO15Amount = !editInvoicePO15Amount">Betrag</th>
              <td *ngIf="!editInvoicePO15Amount">{{operation.invoice_po15_order_amount}} <span
                  *ngIf="operation.invoice_po15_order_amount">€</span></td>
              <td *ngIf="editInvoicePO15Amount">
                <hawk-shared-edit-input [numberValue]="operation.invoice_po15_order_amount" [modus]="'number'"
                  (saveClicked)="saveInvoicePO15Amount($event)" (cancelClicked)="editInvoicePO15Amount = false">
                </hawk-shared-edit-input>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'files'">
      <div class="columns">
        <section>
          <h3>hinterlegte Dateien</h3>
          <table class="base">
            <tr *ngFor="let file of files; let i = index" [attr.data-index]="i">

              <td>
                <a href="{{file.path}}" target="_blank">{{file.filename}}</a><br>
                {{file.updated_at | date: 'dd.MM.yyyy HH:mm'}}, {{file.size | filesize }},
                <a [routerLink]="[]" queryParamsHandling="preserve" (click)="deleteFile(file.filename)">löschen</a>,
                <a [routerLink]="[]" queryParamsHandling="preserve" (click)="editFileName[i] = true">rename</a>
                <ng-container *ngIf="editFileName[i]">
                  <hawk-shared-edit-input [textValue]="file.filename"
                    (saveClicked)="updateFilename(file.filename, $event, i)" (cancelClicked)="editFileName[i] = false">
                  </hawk-shared-edit-input>
                </ng-container>
              </td>
            </tr>
            <tr>
              <td>
                <a href="/r/api/v1/operations/{{operation.id}}/report_{{operation.id}}_{{ticket.id}}.pdf"
                  target="_blank">Servicebericht Einsatz (pdf)</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="/r/api/v1/tickets/{{ticket.id}}/report_{{ticket.id}}.pdf" target="_blank">Servicebericht Ticket
                  (pdf)</a>
              </td>
            </tr>
          </table>
        </section>
        <section>
          <h3>neue Datei hochladen</h3>
          <table class="base">
            <tr>
              <th>Dateiauswahl</th>
              <td>
                <hawk-shared-file-input (saveClicked)="saveUploadedFile($event)" [multiUpload]="true">
                </hawk-shared-file-input>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'extras'">
      <div class="columns">
        <section>
          <h3>Workflow Schritte ändern</h3>
          <hawk-workflow-upload-applet [ticketId]="ticket.id" [operationId]="operation.id">
          </hawk-workflow-upload-applet>
        </section>
        <section>
          <h3>Berichte neu erzeugen</h3>
          <table class="base">
            <tr>
              <th>Einsatzbericht </th>
              <td>
                <a href="/r/api/v1/operations/{{operation.id}}/regenerate_report.pdf">neu erzeugen</a>
              </td>
            </tr>
          </table>
        </section>
      </div>
      <div class="columns">
        <section>
          <h3>hinterlegte Workflow Schritte</h3>
          <hawk-workflow-upload-applet [projectId]="ticket.project_id"></hawk-workflow-upload-applet>
        </section>
        <section>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedTab == 'mt_bonus'">
      <div class="columns">
        <section>
          <h3>Ablehnung hinterlegen</h3>
          <p>hierdurch wird der Status auf Rejected geändert und der entsprechende Code an MT übertragen.</p>
          
          <table class="base">
            <tr>
              <th>Grund:</th>
              <td>
                <select [(ngModel)]="mtRejectionSelectedReason" style="width: 400px" [disabled]="!canReject">
                  <option *ngFor="let typ of mtRejectionReasons" [ngValue]="typ.key">
                      {{typ.title}}
                  </option>
                </select>
              </td>
            </tr>

            <tr>
              <th>TSRC:</th>
              <td>
                {{ mtRejectionSelectedReason }}
              </td>
            </tr>
        
            <tr *ngIf="mtRejectionSelectedReason">
              <th>Freitextrückmeldung</th>
              <td>
                <input type="text" maxlength="999" [(ngModel)]="mtRejectionText" placeholder="Begründung eingeben" style="width: 100%" [disabled]="!canReject">
              </td>
            </tr>

            <tr *ngIf="mtRejectionSelectedReason">
              <td>
                <button (click)="sendRejection()" [disabled]="!canReject">Ticket ablehnen</button>
                <br>
                Aktion nicht umkehrbar!
              </td>
              <td>
              </td>
            </tr>
  
          </table>
        </section>
      </div>
      <div class="columns">
        <section>
          <h3>XML Log</h3>
          <p>folgende Dateien wurden an MT übertragen</p>
          
          <pre *ngFor="let response of ticket.mt_responses">{{ response | json }}</pre>
        </section>
      </div>
    </ng-container>
  </section>
</div>