<table class="diff-table" [class.root-level]="level === 0">
  <tbody>
    @for (key of getKeys(current); track key) { @if
    (isTopLevelItemSectionKey(key)) {
    <tr class="section-header-row">
      <td colspan="2" class="section-header">{{ key | titlecase }}</td>
    </tr>
    @if (key === 'items') { @for (itemId of getItemIds(current?.[key],
    original?.[key]); track itemId) {
    <tr class="item-header-row">
      <td
        class="item-id"
        [class.item-added]="!original?.[key]?.[itemId]"
        [class.item-deleted]="!current?.[key]?.[itemId]"
      >
        ID: {{ itemId }} @if (!original?.[key]?.[itemId]) {
        <span class="status">(Neu)</span> } @if (!current?.[key]?.[itemId]) {
        <span class="status">(Gelöscht)</span> }
      </td>
      <td></td>
    </tr>
    @if (current?.[key]?.[itemId] || original?.[key]?.[itemId]) {
    <tr class="item-detail-row">
      <td colspan="2" class="item-details">
        <app-simple-json-diff
          [current]="current?.[key]?.[itemId]"
          [original]="original?.[key]?.[itemId]"
          [level]="level + 1"
        ></app-simple-json-diff>
      </td>
    </tr>
    } } @empty {
    <tr class="empty-row">
      <td colspan="2" class="empty-message item-details">Keine {{ key }}.</td>
    </tr>
    } } @if (key === 'manual_items') { @for (sn of
    getManualItemSns(current?.[key], original?.[key]); track sn) {
    <tr class="item-header-row">
      <td
        class="item-id"
        [class.item-added]="!findManualItemBySn(original?.[key], sn)"
        [class.item-deleted]="!findManualItemBySn(current?.[key], sn)"
      >
        SN: {{ sn }} @if (!findManualItemBySn(original?.[key], sn)) {
        <span class="status">(Neu)</span> } @if
        (!findManualItemBySn(current?.[key], sn)) {
        <span class="status">(Gelöscht)</span> }
      </td>
      <td></td>
    </tr>
    @if (findManualItemBySn(current?.[key], sn) ||
    findManualItemBySn(original?.[key], sn)) {
    <tr class="item-detail-row">
      <td colspan="2" class="item-details">
        <app-simple-json-diff
          [current]="findManualItemBySn(current?.[key], sn)"
          [original]="findManualItemBySn(original?.[key], sn)"
          [level]="level + 1"
        ></app-simple-json-diff>
      </td>
    </tr>
    } } @empty {
    <tr class="empty-row">
      <td colspan="2" class="empty-message item-details">Keine {{ key }}.</td>
    </tr>
    } } } @else { @if (key === 'sst_results' && (current?.[key] ||
    original?.[key])) {
    <tr class="subsection-header-row">
      <td colspan="2" class="subsection-header" [ngStyle]="getIndentStyle()">
        SST Ergebnisse
      </td>
    </tr>
    @for (sstId of getSstIds(current?.[key], original?.[key]); track sstId) {
    <tr class="sst-header-row">
      <td
        class="sst-id"
        [class.item-added]="!original?.[key]?.[sstId]"
        [class.item-deleted]="!current?.[key]?.[sstId]"
        [ngStyle]="getIndentStyle()"
      >
        SST: {{ sstId }} @if (!original?.[key]?.[sstId]) {
        <span class="status">(Neu)</span> } @if (!current?.[key]?.[sstId]) {
        <span class="status">(Gelöscht)</span> }
      </td>
      <td></td>
    </tr>
    @if (current?.[key]?.[sstId] || original?.[key]?.[sstId]) {
    <tr class="sst-detail-row">
      <td colspan="2" class="sst-details">
        <app-simple-json-diff
          [current]="current?.[key]?.[sstId]"
          [original]="original?.[key]?.[sstId]"
          [level]="level + 1"
        ></app-simple-json-diff>
      </td>
    </tr>
    } } @empty {
    <tr class="empty-row" [ngStyle]="getIndentStyle()">
      <td colspan="2" class="empty-message sst-details">
        Keine SST Ergebnisse vorhanden.
      </td>
    </tr>
    } } @else if (key === 'checklist' && (current?.[key] || original?.[key])) {
    <tr class="subsection-header-row">
      <td colspan="2" class="subsection-header" [ngStyle]="getIndentStyle()">
        Checkliste
      </td>
    </tr>
    @for (qNumber of getChecklistQuestionNumbers(current?.[key],
    original?.[key]); track qNumber) {
    <tr class="checklist-header-row">
      <td
        class="checklist-qnumber"
        [class.item-added]="!original?.[key]?.[qNumber]"
        [class.item-deleted]="!current?.[key]?.[qNumber]"
        [ngStyle]="getIndentStyle()"
      >
        Frage #{{ qNumber }} @if (!original?.[key]?.[qNumber]) {
        <span class="status">(Neu)</span> } @if (!current?.[key]?.[qNumber]) {
        <span class="status">(Gelöscht)</span> }
      </td>
      <td></td>
    </tr>
    @if (current?.[key]?.[qNumber] || original?.[key]?.[qNumber]) {
    <tr class="checklist-detail-row">
      <td colspan="2" class="checklist-details">
        <app-simple-json-diff
          [current]="current?.[key]?.[qNumber]"
          [original]="original?.[key]?.[qNumber]"
          [level]="level + 1"
        >
        </app-simple-json-diff>
      </td>
    </tr>
    } } } @else { @if (!(level === 1 && key === 'sst_ids')) {
    <tr [ngStyle]="getIndentStyle()">
      <td
        class="diff-key"
        [class.key-deleted]="isDeleted(key)"
        [class.key-added]="isAdded(key)"
      >
        {{ key }}:
      </td>
      <td class="diff-value">
        @if (isObject(current?.[key]) || isArray(current?.[key]) ||
        isObject(original?.[key]) || isArray(original?.[key])) { @if (key ===
        'photo' && (current?.[key]?.data || original?.[key]?.data)) {
        <div class="simple-value-wrapper">
          @if (!valuesAreEqual(key) && !isDeleted(key) && !isAdded(key)) {
          <div class="value-changed photo-container">
            @if (current?.[key]?.data) {
            <span class="value-new">
              <img
                class="checklist-photo-image"
                [src]="'data:image/' + (current?.[key]?.filetype?.toLowerCase() || 'jpeg') + ';base64,' + current[key].data"
                alt="{{ key }} (Neu)"
              />
            </span>
            } @else { <span class="value-new">-</span> } @if
            (original?.[key]?.data) {
            <span class="value-old">
              <img
                class="checklist-photo-image"
                [src]="'data:image/' + (original?.[key]?.filetype?.toLowerCase() || 'jpeg') + ';base64,' + original[key].data"
                alt="{{ key }} (Alt)"
              />
            </span>
            } @else { <span class="value-old">-</span> }
          </div>
          } @if (valuesAreEqual(key) && !isDeleted(key) && !isAdded(key) &&
          current?.[key]?.data) {
          <div>
            <img
              class="checklist-photo-image"
              [src]="'data:image/' + (current?.[key]?.filetype?.toLowerCase() || 'jpeg') + ';base64,' + current[key].data"
              alt="{{ key }}"
            />
          </div>
          } @if (isDeleted(key) && original?.[key]?.data) {
          <div class="value-deleted">[Gelöschtes Foto: {{ key }}]</div>
          } @if (isAdded(key) && current?.[key]?.data) {
          <div class="value-added">
            <img
              class="checklist-photo-image"
              [src]="'data:image/' + (current?.[key]?.filetype?.toLowerCase() || 'jpeg') + ';base64,' + current[key].data"
              alt="{{ key }} (Neu)"
            />
          </div>
          }
        </div>
        } @else {
        <app-simple-json-diff
          [current]="current?.[key]"
          [original]="original?.[key]"
          [level]="level + 1"
        >
        </app-simple-json-diff>
        } } @else {
        <div class="simple-value-wrapper">
          @if (key.endsWith('_svg')) { @if (!valuesAreEqual(key) &&
          !isDeleted(key) && !isAdded(key)) {
          <div class="value-changed svg-container">
            @if (current?.[key]) {
            <span class="value-new">
              <img
                class="svg-diff-image"
                [src]="current[key]"
                alt="{{ key }} (Neu)"
              />
            </span>
            } @else { <span class="value-new">-</span> } @if (original?.[key]) {
            <span class="value-old">
              <img
                class="svg-diff-image"
                [src]="original[key]"
                alt="{{ key }} (Alt)"
              />
            </span>
            } @else { <span class="value-old">-</span> }
          </div>
          } @if (valuesAreEqual(key) && !isDeleted(key) && !isAdded(key) &&
          current?.[key]) {
          <div>
            <img class="svg-diff-image" [src]="current[key]" alt="{{ key }}" />
          </div>
          } @if (isDeleted(key) && original?.[key]) {
          <div class="value-deleted">[Gelöschtes SVG: {{ key }}]</div>
          } @if (isAdded(key) && current?.[key]) {
          <div class="value-added">
            <img
              class="svg-diff-image"
              [src]="current[key]"
              alt="{{ key }} (Neu)"
            />
          </div>
          } } @else { @if (!valuesAreEqual(key) && !isDeleted(key) &&
          !isAdded(key)) {
          <div class="value-changed">
            <span
              class="value-new"
              >{{ formatSimpleValue(current?.[key], key) }}</span
            >
            <span
              class="value-old"
              >{{ formatSimpleValue(original?.[key], key) }}</span
            >
          </div>
          } @if (valuesAreEqual(key) && !isDeleted(key) && !isAdded(key)) {
          <div>{{ formatSimpleValue(current?.[key], key) }}</div>
          } @if (isDeleted(key)) {
          <div class="value-deleted">
            {{ formatSimpleValue(original?.[key], key) }}
          </div>
          } @if (isAdded(key)) {
          <div class="value-added">
            {{ formatSimpleValue(current?.[key], key) }}
          </div>
          } }
        </div>
        }
      </td>
    </tr>
    } } } } @empty { @if (level === 0) {
    <tr>
      <td colspan="2" class="empty-message">
        Keine Daten zum Anzeigen vorhanden.
      </td>
    </tr>
    } }
  </tbody>
</table>
