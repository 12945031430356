import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from "@angular/material/button";
import { FormModalComponent } from "../form-modal-component.class";
import { NgIf } from "@angular/common";
import { CreateTaskAttributes } from "../../../../_models/task.interface";
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { MatOption, MatSelect, MatSelectModule } from "@angular/material/select";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { ProjectService } from "../../../../_services";
import { FullProjectHash } from "../../../../_models/project.interface";
import { Observable } from "rxjs";

@Component({
  selector: 'hawk-add-new-task-modal',
  imports: [
    MatButton,
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatCheckbox,
    MatLabel,
    FaIconComponent,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule
  ],
  templateUrl: './add-new-task-modal.component.html',
  standalone: true,
  styleUrls: ['./add-new-task-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class AddNewTaskModalComponent extends FormModalComponent<CreateTaskAttributes, FullProjectHash> {

  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash> = new EventEmitter<FullProjectHash>();

  constructor(
    private projectService: ProjectService,
  ) {
    super();
  }

  override formGroupToDBInput(): CreateTaskAttributes {
    return {
      create_task: {
        name: this.formGroup.controls['name'].value,
        sms: this.formGroup.controls['sms'].value,
        deadline_days: this.formGroup.controls['deadline_days'].value,
        deadline_hours: this.formGroup.controls['deadline_hours'].value,
        deadline_minutes: this.formGroup.controls['deadline_minutes'].value,
        mail: this.formGroup.controls['mail'].value,
        project_id: this.formGroup.controls['project_id'].value,
        use_deadline: this.formGroup.controls['use_deadline'].value,
      },
      user_ids: this.formGroup.controls['user_ids'].value,
      deadline_user_ids: this.formGroup.controls['deadline_user_ids'].value,
    }
  }

  override getDBCallObservable(create_task_attributes: CreateTaskAttributes): Observable<FullProjectHash> {
    return this.projectService.createProjectTask(create_task_attributes.create_task.project_id, create_task_attributes)
  }

  protected readonly faMailBulk = faMailBulk;
}
