import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormModalComponent } from "../form-modal-component.class";
import { MatButton } from "@angular/material/button";
import { CreatePriorityAttributes } from "../../../../_models/priority.interface";
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { NgIf } from "@angular/common";
import { FullProjectHash } from "../../../../_models/project.interface";
import { ProjectService } from "../../../../_services";
import { Observable } from "rxjs";

@Component({
  selector: 'hawk-add-new-priority-modal',
  imports: [
    MatButton,
    FormsModule,
    MatCheckbox,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule
  ],
  templateUrl: './add-new-priority-modal.component.html',
  standalone: true,
  styleUrls: ['./add-new-priority-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class AddNewPriorityModalComponent extends FormModalComponent<CreatePriorityAttributes, FullProjectHash> {

  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash> = new EventEmitter<FullProjectHash>();

  constructor(
    private projectService: ProjectService,
  ) {
    super();
  }

  override formGroupToDBInput(): CreatePriorityAttributes {
    return {
      name: this.formGroup.controls['name'].value,
      color: this.formGroup.controls['color'].value,
      days: this.formGroup.controls['days'].value,
      hours: this.formGroup.controls['hours'].value,
      material_return: this.formGroup.controls['material_return'].value,
      priority_idx: this.formGroup.controls['priority_idx'].value,
      sla_type: this.formGroup.controls['sla_type'].value,
      telephone_dispatching: this.formGroup.controls['telephone_dispatching'].value,
      project_id: this.formGroup.controls['project_id'].value,
    }
  }

  override getDBCallObservable(create_priority_attributes: CreatePriorityAttributes): Observable<FullProjectHash> {
    return this.projectService.createProjectPriority(create_priority_attributes.project_id, create_priority_attributes)
  }

}
