// src/app/components/mt-result/mt-result.component.ts (Pfad anpassen)
import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtResultResponse, MtResultHash, MtResultItem } from 'src/app/_models/mt-result.interface'; // Pfad anpassen
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { TicketService } from 'src/app/_services';
import { SimpleJsonDiffComponent } from '../_shared/simple-json-diff/simple-json-diff.component';

@Component({
  selector: 'app-mt-result',
  standalone: true,
  imports: [CommonModule, SimpleJsonDiffComponent], // SimpleJsonDiffModule hinzufügen
  templateUrl: './mt-result.component.html',
  styleUrls: ['./mt-result.component.scss']
})
export class MtResultComponent implements OnInit, OnChanges {
  @Input() ticketId!: number

  mtResult$: Observable<MtResultResponse | null> | undefined;
  isLoading = false;
  error: string | null = null;

  constructor(private ticketService: TicketService) {}

  ngOnInit(): void {
    if (this.ticketId) {
      this.loadMtResult();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Wenn sich die ticketId ändert, Daten neu laden
    if (changes['ticketId'] && !changes['ticketId'].firstChange) {
      this.loadMtResult();
    }
  }

  loadMtResult(): void {
    this.isLoading = true;
    this.error = null;
    this.mtResult$ = this.ticketService.getMtResult(this.ticketId).pipe(
      catchError(err => {
        console.error('Fehler beim Laden der MT Result Daten:', err);
        this.error = 'Fehler beim Laden der Daten. Details siehe Konsole.';
        return of(null); // Gib ein null-Observable zurück im Fehlerfall
      }),
      finalize(() => this.isLoading = false)
    );
  }

  // Helper um Keys für *ngFor zu bekommen
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

   // Prüft, ob ein Item gelöscht wurde
   isItemDeleted(sn: string | null | undefined, originalData: MtResultHash | null): boolean {
    if (!sn || !originalData) return false;

    const originalItem = this.findItemBySn(originalData, sn);
    // Wenn es im Original existiert, aber nicht mehr in current (wird im Diff behandelt)
    // Wir brauchen diese Methode vielleicht nicht direkt, wenn das Diff-Modul das abdeckt.
    return !!originalItem; // Vorerst nur prüfen, ob es im Original war
  }

  // Findet ein Item (aus items oder manual_items) anhand der SN
  findItemBySn(data: MtResultHash | null, sn: string): MtResultItem | undefined {
    if (!data) return undefined;
    const itemById = data.items ? Object.values(data.items).find(item => item?.item_serial_number === sn) : undefined;
    if (itemById) return itemById;
    const manualItem = data.manual_items?.find(item => item?.item_serial_number === sn);
    return manualItem;
  }


  // --- Zusätzliche Formatierungs-Helper nach Bedarf ---
  formatValue(value: any): string {
     if (value === true) return 'Ja';
     if (value === false) return 'Nein';
     if (value instanceof Date) return value.toLocaleString(); // Einfache Datumsformatierung
     if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
       try { return new Date(value).toLocaleString(); } catch (e) { /* fallback */ }
     }
     if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
      try { return new Date(value).toLocaleDateString(); } catch (e) { /* fallback */ }
    }
     return value !== null && value !== undefined ? String(value) : '';
   }
}