<div class="common_style">
  <section>
    <h2 class="flex_row">
      Status
      <div class="pseudo-link" (click)="openAddStatusForm()">+ hinzufügen</div>
    </h2>
    <table class="section_table" style="font-size: 14px;">
      <tbody>
      <tr *ngFor="let status of project.status">
        <th class="editable" (click)="openEditStatusForm(status)">
          {{status.name}}
          <fa-icon *ngIf="status.closed" class="text-success" [tooltip]="'Closed'" [icon]="faCheckCircle"></fa-icon>
          <fa-icon *ngIf="status.canceled" class="text-danger" [tooltip]="'Canceled'" [icon]="faBan"></fa-icon>
          <fa-icon *ngIf="status.report_closed" class="text-muted" [tooltip]="'Report closed'" [icon]="faHandPaper"></fa-icon>
        </th>
        <td><ng-container *ngIf="status.mail_template">{{status.mail_template.subject}}
          <ng-container *ngIf="status.email">({{status.email}})</ng-container>
        </ng-container></td>
        <td style="white-space: nowrap;">Tickets: {{status.ticket_amount}}</td>
        <td style="white-space: nowrap;">ID: {{status.id}}</td>
        <td style="text-align: right;"><fa-icon *ngIf="!status.ticket_amount" [icon]="faTrash"></fa-icon></td>
      </tr>
      </tbody>
    </table>
  </section>
  <!--<section>
    <h2 class="flex_row">
      Aufgaben
      <div class="pseudo-link" (click)="openAddTaskForm()">+ hinzufügen</div>
    </h2>
    <table class="section_table" style="font-size: 14px;">
      <tbody>
      <tr *ngFor="let task of project.tasks">
        <th class="editable" (click)="openEditTaskForm(task)">{{task.name}}</th>
        <td style="white-space: nowrap;">Tickets: {{task.ticket_amount}}</td>
        <td style="white-space: nowrap;">ID: {{task.id}}</td>
        <td style="text-align: right;"><fa-icon *ngIf="!task.ticket_amount" [icon]="faTrash"></fa-icon></td>
      </tr>
      </tbody>
    </table>
  </section>-->
  <section>
    <h2 class="flex_row">
      Priorität
      <div class="pseudo-link" (click)="openAddPriorityForm()">+ hinzufügen</div>
    </h2>
    <table class="section_table" style="font-size: 14px;">
      <tbody>
      <tr *ngFor="let priority of project.priorities">
        <th class="editable" (click)="openEditPriorityForm(priority)">
          <div class="flex_row">
            <div style="width: 16px; height: 16px; border: 1px solid black; border-radius: 8px; margin: auto 0;" [style.background-color]="priority.color"></div>
            <div style="margin: auto 4px">{{priority.name}}</div>
          </div>
        </th>
        <td>Tickets: {{priority.ticket_amount}}</td>
        <td>ID: {{priority.id}}</td>
        <td style="text-align: right;"><fa-icon *ngIf="!priority.ticket_amount" [icon]="faTrash"></fa-icon></td>
      </tr>
      </tbody>
    </table>
  </section>

  <hawk-add-new-priority-modal *ngIf="addPriorityForm" [formGroup]="addPriorityForm" (closeModal)="addPriorityForm=null"></hawk-add-new-priority-modal>
  <hawk-add-new-status-modal *ngIf="addStatusForm" [formGroup]="addStatusForm" (closeModal)="addStatusForm=null"></hawk-add-new-status-modal>
  <hawk-add-new-task-modal *ngIf="addTaskForm" [formGroup]="addTaskForm" (closeModal)="addTaskForm=null"></hawk-add-new-task-modal>
  <hawk-edit-priority-modal *ngIf="editPriorityForm" [formGroup]="editPriorityForm" (closeModal)="editPriorityForm=null"></hawk-edit-priority-modal>
  <hawk-edit-status-modal *ngIf="editStatusForm" [formGroup]="editStatusForm" (closeModal)="editStatusForm=null"></hawk-edit-status-modal>
  <hawk-edit-task-modal *ngIf="editTaskForm" [formGroup]="editTaskForm" (closeModal)="editTaskForm=null"></hawk-edit-task-modal>
</div>