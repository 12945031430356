import { Component, OnInit } from '@angular/core';
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CustomerService, ProjectService } from "../../_services";
import { MatFormField, MatHint } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MatOption } from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { APCustomer } from "../../_models/customer.interface";
import { NgForOf } from "@angular/common";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import { MatButton } from "@angular/material/button";

@Component({
  selector: 'hawk-project-create',
  imports: [
    RecordNavigationComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatHint,
    MatInput,
    MatOption,
    MatSelect,
    MatRadioGroup,
    MatRadioButton,
    NgForOf,
    MatCheckbox,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerToggle,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerModule,
    MatNativeDateModule,
    FaIconComponent,
    TooltipModule,
    MatButton
  ],
  templateUrl: './project-create.component.html',
  standalone: true,
  styleUrls: ['./project-create.component.scss', '../_shared/styles/common-styles.scss']
})
export class ProjectCreateComponent implements OnInit {

  projectForm: UntypedFormGroup;
  customers: APCustomer[] = []

  constructor(
    private projectService: ProjectService,
    private customerService: CustomerService
  ) {
  }

  ngOnInit(): void {
    this.initProjectForm()
    this.loadCustomers()
  }

  loadCustomers(): void {
    this.customerService.loadAPCustomers().subscribe(
      data => this.customers = data,
      err => console.log(err)
    )
  }

  initProjectForm(): void {
    this.projectForm = this.getProjectForm()
  }

  getProjectForm(): UntypedFormGroup {
    let form = new UntypedFormGroup({
      "name": new UntypedFormControl({value: ''}, Validators.required),
      "description": new UntypedFormControl({value: ''}),
      "type": new UntypedFormControl({value: ''}, Validators.required),
      "customer_id": new UntypedFormControl({value: null}, Validators.required),
      "cost_center": new UntypedFormControl({value: ''}),
      "cut_off_time": new UntypedFormControl({value: ''}),
      "select_products": new UntypedFormControl({value: false}),
      "contact": new UntypedFormControl({value: ''}),
      "email": new UntypedFormControl({value: ''}),
      "start": new UntypedFormControl({value: ''}),
      "end": new UntypedFormControl({value: ''}),
      "send_operation_new": new UntypedFormControl({value: false}),
      "send_operation_update": new UntypedFormControl({value: false}),
      "send_operation_delete": new UntypedFormControl({value: false}),
      "additional_documentation": new UntypedFormControl({value: ''}),
    })

    form.controls['name'].setValue('')
    form.controls['description'].setValue('')
    form.controls['type'].setValue('')
    form.controls['customer_id'].setValue(null)
    form.controls['cost_center'].setValue('')
    form.controls['cut_off_time'].setValue('')
    form.controls['select_products'].setValue(false)
    form.controls['contact'].setValue('')
    form.controls['email'].setValue('')
    form.controls['start'].setValue('')
    form.controls['end'].setValue('')
    form.controls['send_operation_new'].setValue(false)
    form.controls['send_operation_update'].setValue(false)
    form.controls['send_operation_delete'].setValue(false)
    form.controls['additional_documentation'].setValue('')

    form.updateValueAndValidity()

    return form
  }

  onSubmit() {
    // TODO create Project
  }

  protected readonly faInfoCircle = faInfoCircle;
}
