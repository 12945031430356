import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormModalComponent } from "../form-modal-component.class";
import { FullProjectHash } from "../../../../_models/project.interface";
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CreateStatusAttributes } from "../../../../_models/status.interface";
import { MatButton } from "@angular/material/button";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { NgIf } from "@angular/common";
import { ProjectService } from "../../../../_services";

@Component({
  selector: 'hawk-edit-status-modal',
  imports: [
    FormsModule,
    MatButton,
    MatCheckbox,
    MatFormField,
    MatInput,
    MatOption,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatLabel
  ],
  templateUrl: './edit-status-modal.component.html',
  standalone: true,
  styleUrls: ['./edit-status-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class EditStatusModalComponent extends FormModalComponent<CreateStatusAttributes, FullProjectHash> {

  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash> = new EventEmitter<FullProjectHash>();

  constructor(
    private projectService: ProjectService,
  ) {
    super();
  }

  override formGroupToDBInput(): CreateStatusAttributes {
    return {
      id: this.formGroup.controls['id'].value,
      name: this.formGroup.controls['name'].value,
      closed: this.formGroup.controls['closed'].value,
      report_closed: this.formGroup.controls['report_closed'].value,
      canceled: this.formGroup.controls['canceled'].value,
      color: this.formGroup.controls['color'].value,
      email: this.formGroup.controls['email'].value,
      fixer_forbidden: this.formGroup.controls['fixer_forbidden'].value,
      project_id: this.formGroup.controls['project_id'].value,
    }
  }

  override getDBCallObservable(create_status_attributes: CreateStatusAttributes): Observable<FullProjectHash> {
    return this.projectService.updateProjectStatus(create_status_attributes.project_id, create_status_attributes.id, create_status_attributes)
  }

}
