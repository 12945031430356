<hawk-record-navigation class="common_style" *ngIf="project" [breadcrumbLinkList]="[{description: 'Projekte', link: '/a/projekt'}]" [root]="'project'" [leafDescription]="project.name">
  <div class="card-body flex_col">
    <div style="flex: 1;" class="responsive_split">
      <div style="flex: 1;" class="flex_col">
        <div class="flex_row">
          <section>
            <h2>Stammdaten</h2>
            <table class="section_table">
              <tbody>
              <tr>
                <th class="editable" (click)="editName = !editName">Name</th>
                <td *ngIf="!editName">{{project.name}}</td>
                <td *ngIf="editName"><hawk-shared-edit-input [textValue]="project.name" (saveClicked)="saveName($any($event))" (cancelClicked)="editName = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editActive = !editActive">Aktiv</th>
                <td *ngIf="!editActive">
                  <ng-container *ngIf="project.active">
                    <fa-icon [icon]="faCheck"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="!project.active">
                    &#10060;
                  </ng-container>
                </td>
                <td *ngIf="editActive"><hawk-shared-edit-input [modus]="'checkbox'" [boolValue]="project.active" (saveClicked)="saveActive($any($event))" (cancelClicked)="editActive = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editDescription = !editDescription">Beschreibung</th>
                <td *ngIf="!editDescription">
                  {{project.description}}
                </td>
                <td *ngIf="editDescription"><hawk-shared-edit-input [textValue]="project.description" (saveClicked)="saveDescription($any($event))" (cancelClicked)="editDescription = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editCustomer = !editCustomer">Kunde</th>
                <td *ngIf="!editCustomer">
                  <a [routerLink]="'/kunde/' + project.customer.id">
                    <fa-icon [icon]="faIndustry"></fa-icon>
                    {{project.customer.name}}
                  </a>
                </td>
                <td *ngIf="editCustomer"><hawk-shared-edit-input [selectValue]="project.customer.id" [modus]="'select'" [selectOptions]="customers" (saveClicked)="saveCustomer($any($event))" (cancelClicked)="editCustomer = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editType = !editType">Typ</th>
                <td *ngIf="!editType">
                  {{projectTypeTranslations[project.type].name}}
                </td>
                <td *ngIf="editType"><hawk-shared-edit-input [selectValue]="selectedProjectTypeID" [modus]="'select'" [selectOptions]="selectableProjectTypes" (saveClicked)="saveType($any($event))" (cancelClicked)="editType = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editCostCenter = !editCostCenter">Kostenstelle</th>
                <td *ngIf="!editCostCenter">
                  {{project.cost_center}}
                </td>
                <td *ngIf="editCostCenter"><hawk-shared-edit-input [textValue]="project.cost_center" (saveClicked)="saveCostCenter($any($event))" (cancelClicked)="editCostCenter = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editCutOffTime = !editCutOffTime">Cut off time (SLA +1Tag)</th>
                <td *ngIf="!editCutOffTime">
                  {{project.cut_off_time}}
                </td>
                <td *ngIf="editCutOffTime"><hawk-shared-edit-input [timeValue]="project.cut_off_time" [modus]="'time'" [selectOptions]="customers" (saveClicked)="saveCutOffTime($any($event))" (cancelClicked)="editCutOffTime = false"></hawk-shared-edit-input></td>
              </tr>
              <!--<tr>
                <th class="editable" (click)="editStores = !editStores">Stores</th>
                <td>
                  {{project.stores.length}}
                </td>
              </tr>-->
              <tr>
                <th class="editable" (click)="editDispatchers = !editDispatchers">Dispatcher</th>
                <td *ngIf="!editDispatchers">
                  <div style="flex: 1; flex-wrap: wrap;" class="flex_row">
                    <div *ngFor="let dispatcher of project.dispatchers" class="flex_row" style="margin-right: 6px;">
                      <a [routerLink]="'/benutzer/' + dispatcher.id">
                        <fa-icon [icon]="faUser"></fa-icon>
                        {{dispatcher.name}}
                      </a>
                    </div>
                  </div>
                </td>
                <td *ngIf="editDispatchers"><hawk-shared-edit-input [multiSelectValue]="selectedDispatcherIds" [modus]="'multiselect'" [selectOptions]="dispatchers" (saveClicked)="saveDispatchers($any($event))" (cancelClicked)="editDispatchers = false"></hawk-shared-edit-input></td>
              </tr>
              </tbody>
            </table>
          </section>
          <div class="flex_row">
            <section class="flex_row">
              <h2 class="flex_row" style="z-index: 2;">
                Logo
                <div class="pseudo-link">Hochladen</div>
              </h2>
              <div style="width: fit-content;">
                <img style="object-fit: contain; width: auto; position: relative;" [ngSrc]="project.logo" alt="Projekt-Logo" fill>
              </div>
            </section>
          </div>
        </div>
        <section>
          <h2>Dokumentation</h2>
          <table class="section_table">
            <tbody>
            <tr>
              <th class="editable" (click)="editDocumentation = !editDocumentation">Link</th>
              <td *ngIf="!editDocumentation">
                <a *ngIf="project.additional_documentation" [href]="project.additional_documentation">
                  {{project.additional_documentation}}
                </a>
              </td>
              <td *ngIf="editDocumentation">
                <hawk-shared-edit-input [textValue]="project.additional_documentation" (saveClicked)="saveDocumentation($any($event))" (cancelClicked)="editDocumentation = false"></hawk-shared-edit-input>
              </td>
            </tr>
            </tbody>
          </table>
        </section>
        <div class="flex_row">
          <section>
            <h2>Kontakt</h2>
            <table class="section_table">
              <tbody>
              <tr>
                <th class="editable" (click)="editContact = !editContact">Daten</th>
                <td *ngIf="!editContact">{{project.contact}}</td>
                <td *ngIf="editContact"><hawk-shared-edit-input [textValue]="project.contact" (saveClicked)="saveContact($any($event))" (cancelClicked)="editContact = false"></hawk-shared-edit-input></td>
              </tr>
              <tr>
                <th class="editable" (click)="editEmail = !editEmail">Email</th>
                <td *ngIf="!editEmail">
                  <div class="flex_col" *ngIf="project.email">
                    <a *ngFor="let mail of project.email.split(';')" [href]="'mailto:' + mail">{{mail}}</a>
                  </div>
                <td *ngIf="editEmail"><hawk-shared-edit-input [textValue]="project.email" (saveClicked)="saveEmail($any($event))" (cancelClicked)="editEmail = false"></hawk-shared-edit-input></td>
              </tr>
              </tbody>
            </table>
          </section>
          <section>
            <h2 class="flex_row">
              Ticketattribute
              <div class="pseudo-link" (click)="editTicketAttributes = !editTicketAttributes">Bearbeiten</div>
            </h2>
            <table class="section_table">
              <tbody>
              <tr>
                <td>Status</td>
                <td>{{project.status.length}}</td>
              </tr>
              <!--<tr>
                <td>Aufgaben</td>
                <td>{{project.tasks.length}}</td>
              </tr>-->
              <tr>
                <td>Prioritäten</td>
                <td>{{project.priorities.length}}</td>
              </tr>
              </tbody>
            </table>
          </section>

          <section>
            <h2>
              Mailbenachrichtigungen
              <fa-icon style="cursor: pointer;" [icon]="faInfoCircle" tooltip="Techniker eines Einsatzes erhalten bei folgenden Ereignissen eine E-Mail."></fa-icon>
            </h2>
            <table class="section_table">
              <tbody>
              <tr>
                <th class="editable" (click)="editSendOperationNew=!editSendOperationNew;">Neuer Einsatz</th>
                <td *ngIf="!editSendOperationNew">
                  <fa-icon *ngIf="project.send_operation_new" [icon]="faCheck"></fa-icon>
                  <div *ngIf="!project.send_operation_new">-</div>
                </td>
                <td *ngIf="editSendOperationNew">
                  <hawk-shared-edit-input [modus]="'checkbox'" [boolValue]="project.send_operation_new" (saveClicked)="saveSendOperationNew($any($event))" (cancelClicked)="editSendOperationNew = false"></hawk-shared-edit-input>
                </td>
              </tr>
              <tr>
                <th class="editable" (click)="editSendOperationUpdate=!editSendOperationUpdate;">Einsatz geändert</th>
                <td *ngIf="!editSendOperationUpdate">
                  <fa-icon *ngIf="project.send_operation_update" [icon]="faCheck"></fa-icon>
                  <div *ngIf="!project.send_operation_update">-</div>
                </td>
                <td *ngIf="editSendOperationUpdate">
                  <hawk-shared-edit-input [modus]="'checkbox'" [boolValue]="project.send_operation_update" (saveClicked)="saveSendOperationUpdate($any($event))" (cancelClicked)="editSendOperationUpdate = false"></hawk-shared-edit-input>
                </td>
              </tr>
              <tr>
                <th class="editable" (click)="editSendOperationDelete=!editSendOperationDelete;">Einsatz gelöscht (Storno)</th>
                <td *ngIf="!editSendOperationDelete">
                  <fa-icon *ngIf="project.send_operation_delete" [icon]="faCheck"></fa-icon>
                  <div *ngIf="!project.send_operation_delete">-</div>
                </td>
                <td *ngIf="editSendOperationDelete">
                  <hawk-shared-edit-input [modus]="'checkbox'" [boolValue]="project.send_operation_delete" (saveClicked)="saveSendOperationDelete($any($event))" (cancelClicked)="editSendOperationDelete = false"></hawk-shared-edit-input>
                </td>
              </tr>
              </tbody>
            </table>
          </section>
          <!--<section>
            <h2 class="flex_row">
              <div>Deadline-Vorgaben</div>
              <div class="pseudo-link" (click)="openEditDeadlineModal()">Bearbeiten</div>
            </h2>
            <table class="section_table">
              <tbody>
              <tr>
                <td>Deadline 1 verwenden</td>
                <td *ngIf="project.use_deadline"><fa-icon [icon]="faCheck"></fa-icon></td>
                <td *ngIf="!project.use_deadline"> - </td>
              </tr>
              <tr>
                <td>Deadline 2 verwenden</td>
                <td *ngIf="project.use_deadline2"><fa-icon [icon]="faCheck"></fa-icon></td>
                <td *ngIf="!project.use_deadline2"> - </td>
              </tr>
              <tr>
                <td>Deadline 3 verwenden</td>
                <td *ngIf="project.use_deadline3"><fa-icon [icon]="faCheck"></fa-icon></td>
                <td *ngIf="!project.use_deadline3"> - </td>
              </tr>
              </tbody>
            </table>
          </section>-->
        </div>
        <div class="flex_row">
          <section>
            <h2 class="flex_row">
              <div>Mailtemplates</div>
              <div class="pseudo-link" (click)="openAddMailTemplateForm()">+ hinzufügen</div>
            </h2>
            <table class="section_table">
              <tbody>
              <tr *ngFor="let template of project.mail_templates">
                <td><div style="background-color: #757575; color: white; width: fit-content; border-radius: 4px; padding: 4px 8px;">{{template.type}}</div></td>
                <td>{{template.subject}}</td>
                <td style="text-align: right;">
                  <div class="flex_row">
                    <fa-icon class="pseudo-link mr8" [icon]="faPen" (click)="openEditMailTemplateForm(template)"></fa-icon>
                    <fa-icon class="pseudo-link mr4" [icon]="faTrash"></fa-icon>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </section>
          <section>
            <h2>Status</h2>
            <div *ngFor="let status_count of statusCounts" class="flex_col" style="width: 100%; font-size: 12px;">
              <div class="flex_row">
                <div style="margin-right: auto;">
                  <a [href]="'/a/ticket/suche?project_id=' + project.id + '&status_id=' + status_count.id">{{status_count.name}}</a>
                  <fa-icon *ngIf="status_count.closed" [tooltip]="'Closed'" class="text-success" style="cursor: pointer; margin-left: 6px;" [icon]="faCheckCircle"></fa-icon>
                </div>
                <div style="font-weight: bold;">
                  {{status_count.count}}
                </div>
              </div>
              <mat-progress-bar [class]="status_count.closed ? 'green_bar' : 'orange_bar'" mode="determinate" [value]="(status_count.count / tickets.length) * 100"></mat-progress-bar>
            </div>
          </section>
          <section *ngIf="taskCounts.length">
            <h2>Aufgaben</h2>
            <div *ngFor="let task_count of taskCounts" class="flex_col" style="width: 100%; font-size: 12px;">
              <div class="flex_row">
                <div class="text-muted" style="margin-right: auto;">
                  {{task_count.name}}
                </div>
                <div style="font-weight: bold;">
                  {{task_count.count}}
                </div>
              </div>
              <mat-progress-bar [class]="'blue_bar'" mode="determinate" [value]="(task_count.count / totalTaskCount) * 100"></mat-progress-bar>
            </div>
          </section>
          <section>
            <h2>Prioritäten</h2>
            <div *ngFor="let priority_count of priorityCounts" class="flex_col" style="width: 100%; font-size: 12px;">
              <div class="flex_row">
                <div style="margin-right: auto;">
                  <a [href]="'/a/ticket/suche?project_id=' + project.id + '&priority_id=' + priority_count.id">{{priority_count.name}}</a>
                </div>
                <div style="font-weight: bold;">
                  {{priority_count.count}}
                </div>
              </div>
              <mat-progress-bar [class]="'blue_bar'" mode="determinate" [value]="(priority_count.count / tickets.length) * 100"></mat-progress-bar>
            </div>
          </section>
        </div>
      </div>
      <div class="flex_col" style="flex: 1;">
        <section class="tabs flex_col">
          <div class="tablist" role="tablist">
            <button role="tab" class="tab" [attr.aria-selected]="selectedTab == 'tickets'" (click)="selectTab('tickets')">Tickets</button>
            <button role="tab" class="tab" [attr.aria-selected]="selectedTab == 'technicians'" (click)="selectTab('technicians')">Projekt-Techniker</button>
            <button role="tab" class="tab" [attr.aria-selected]="selectedTab == 'stores'" (click)="selectTab('stores')">Filialen</button>
          </div>
          <div class="flex_col" style="width: calc(100% - 20px); margin: 10px; flex: 1;">
            <ng-container *ngIf="selectedTab == 'tickets'">
              <div class="flex_row">
                <div class="flex_row" style="font-size: 14px; margin-bottom: 4px;">
                  <div class="flex_col right_border_spacer">
                    <div class="text-muted">Gesamt</div>
                    <a [href]="'/a/ticket/suche?project_id=' + project.id">{{tickets.length}}</a>
                  </div>
                  <div class="flex_col right_border_spacer">
                    <div class="text-muted">Offen</div>
                    <a [href]="'/a/ticket/suche?is_open=1&project_id=' + project.id">{{openTicketCount}}</a>
                  </div>
                  <div class="flex_col right_border_spacer">
                    <div class="text-muted">Geschlossen</div>
                    <div>{{closedTicketCount}}</div>
                  </div>
                  <a style="margin: auto 0;" [routerLink]="'/projekt/' + project.id + '/ticket_import'">
                    <button mat-raised-button color="primary" class="primary_bg_color_button">
                      <fa-icon [icon]="faUpload"></fa-icon>
                      Ticket-Import
                    </button>
                  </a>
                </div>
                <input type="text" style="margin: auto 0 4px auto;" placeholder="Schnellfilter..." (input)="ticketQuickSearchChanged($event)">
              </div>
              <ag-grid-angular class="ag-theme-quartz" style="flex: 1; width: 100%;" [rowData]="tickets" [theme]="hawkTheme"
                               [defaultColDef]="defaultColDef" [columnDefs]="ticketColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                               [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                               [paginationPageSize]="16" [localeText]="locale" [sideBar]="sideBarOptions"
                               [paginationPageSizeSelector]="[16,32,64,128]" (gridReady)="onTicketGridReady($event)">
              </ag-grid-angular>
            </ng-container>
            <ng-container *ngIf="selectedTab == 'technicians'">
              <div class="flex_row">
                <mat-checkbox color="primary">Bentomax-Techniker Aktivieren</mat-checkbox>
                <input *ngIf="projectTechnicianFormCellInputs.length != 0" style="margin: auto 0 auto auto;" placeholder="Schnellfilter" (input)="projectTechnicianQuickSearchChanged($event)">
              </div>
              <form (ngSubmit)="onProjectTechnicianSubmit()" [formGroup]="projectTechnicianForm" style="flex: 1; position: relative;" class="flex_row">
                <ag-grid-angular class="ag-theme-quartz" style="flex: 1; width: 100%;" [rowData]="projectTechnicianFormCellInputs" [theme]="oldTheme"
                                 [defaultColDef]="defaultColDef" [columnDefs]="projectTechnicianColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                                 [context]="getProjectTechnicianContext()"
                                 [components]="getAGComponents()"
                                 [getRowId]="getProjectTechnicianRowNodeId"
                                 [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                                 [paginationPageSize]="10" [localeText]="locale" [sideBar]="sideBarOptions"
                                 [paginationPageSizeSelector]="[10,25,100]"
                                 (gridReady)="onProjectTechnicianGridReady($event)"
                                 (rowDataUpdated)="initProjectTechnicianFormControls()">
                </ag-grid-angular>
                <button mat-raised-button style="position: absolute; bottom: 3px; left: 3px;" class="primary_bg_color_button" [disabled]="projectTechnicianForm.untouched || !projectTechnicianForm.valid">Speichern</button>
              </form>
            </ng-container>
            <ng-container *ngIf="selectedTab == 'stores'">
              <div class="flex_row">
                <button mat-raised-button class="primary_bg_color_button">Alle Filialen verknüpfen</button>
                <input style="margin: auto 0 auto auto;" placeholder="Schnellfilter" (input)="storeQuickSearchChanged($event)">
              </div>
              <form (ngSubmit)="onProjectStoreSubmit()" [formGroup]="projectStoreForm" style="flex: 1; position: relative;" class="flex_row">
                <ag-grid-angular class="ag-theme-quartz" style="flex: 1; width: 100%;" [rowData]="projectStoreFormCellInputs" [theme]="oldTheme"
                                 [defaultColDef]="defaultColDef" [columnDefs]="projectStoreColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                                 [context]="getProjectStoreContext()"
                                 [components]="getAGComponents()"
                                 [getRowId]="getStoreRowNodeId"
                                 [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                                 [paginationPageSize]="10" [localeText]="locale" [sideBar]="sideBarOptions"
                                 [paginationPageSizeSelector]="[10,25,100]"
                                 (gridReady)="onProjectStoreGridReady($event)"
                                 (rowDataUpdated)="initProjectStoreFormControls()">
                </ag-grid-angular>
                <button mat-raised-button style="position: absolute; bottom: 3px; left: 3px;" class="primary_bg_color_button" [disabled]="projectStoreForm.untouched || !projectStoreForm.valid">Speichern</button>
              </form>
            </ng-container>
          </div>
        </section>
      </div>
    </div>
  </div>

  <div style="flex-wrap: wrap;" class="flex_row footer_row">
    <a [routerLink]="'/projekt'">
      <button mat-button class="primary_color_button" color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Zurück
      </button>
    </a>
    <a style="margin-left: 6px;" [routerLink]="'/project-temp/' + project.id">
      <button mat-raised-button color="primary" class="primary_bg_color_button">
        <fa-icon [icon]="faUpload"></fa-icon>
        Workflow-Upload
      </button>
    </a>
    <div style="flex: 1;"></div>
    <div [matTooltip]="projectIsDeletable ? '' : 'Projekte können nur gelöscht werden, wenn keine Abhängigkeiten zu TODO vorhanden sind'">
      <button mat-raised-button [disabled]="!projectIsDeletable" (click)="deleteProject()">
        <fa-icon [icon]="faTrash"></fa-icon>
        Löschen
      </button>
    </div>
  </div>

  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="editTicketAttributes">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 1024px;">
      <div class="wrapper" style="padding: 2px;">
        <h4>Ticketattribute bearbeiten</h4>
        <div class="callout" style="margin: 10px;">
          <h4>Info</h4>
          <div>Jedes Ticket besitzt einen <b>Status</b> und eine <b>Priorität</b>, außerdem können dem Ticket mehrere <b>Aufgaben</b> zugeordnet werden.</div>
          <div>Beim Status können Sie festlegen, ob mit diesem das Ticket erledigt ist, welche Aufgaben standardmäßig damit verbunden sind und ob die Projektkontakte über das Erreichen per E-Mail benachrichtigt werden sollen.</div>
        </div>
        <hawk-project-edit-ticket-attributes [project]="project"></hawk-project-edit-ticket-attributes>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left">
        </div>
        <div class="modal-toolbar-container-right">
          <button mat-flat-button (click)="editTicketAttributes=false;">Ok</button>
        </div>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="editTicketAttributes=false"></button>
  </div>

  <hawk-add-new-mail-template-modal *ngIf="addMailTemplateForm" [formGroup]="addMailTemplateForm" (closeModal)="addMailTemplateForm=null"></hawk-add-new-mail-template-modal>
  <hawk-edit-mail-template *ngIf="editMailTemplateForm" [formGroup]="editMailTemplateForm" (closeModal)="editMailTemplateForm=null"></hawk-edit-mail-template>
  <hawk-edit-project-deadlines-modal *ngIf="editDeadlineTemplate" [formGroup]="editDeadlineTemplate" (closeModal)="editDeadlineTemplate=null"></hawk-edit-project-deadlines-modal>
  <hawk-edit-project-technician-modal *ngIf="editProjectTechnicianForm" [userID]="editProjectTechnicianForm.controls['user_id'].value" [formGroup]="editProjectTechnicianForm" (closeModal)="editProjectTechnicianForm=null"></hawk-edit-project-technician-modal>

</hawk-record-navigation>
