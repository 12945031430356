import { Component, Input } from '@angular/core';
import { FullProjectHash } from "../../../_models/project.interface";
import { RecordNavigationComponent } from "../../_shared/record-navigation/record-navigation.component";
import { EditInputComponent } from "../../_shared/edit-input/edit-input.component";
import { NgForOf, NgIf } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faBan, faCheckCircle, faHandPaper, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MatButton } from "@angular/material/button";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AddNewTaskModalComponent } from "../modal-components/add-new-task-modal/add-new-task-modal.component";
import {
  AddNewPriorityModalComponent
} from "../modal-components/add-new-priority-modal/add-new-priority-modal.component";
import { AddNewStatusModalComponent } from "../modal-components/add-new-status-modal/add-new-status-modal.component";
import { FullTaskHash } from "../../../_models/task.interface";
import { FullStatusHash } from "../../../_models/status.interface";
import { FullPriorityHash } from "../../../_models/priority.interface";
import { EditPriorityModalComponent } from "../modal-components/edit-priority-modal/edit-priority-modal.component";
import { EditStatusModalComponent } from "../modal-components/edit-status-modal/edit-status-modal.component";
import { EditTaskModalComponent } from "../modal-components/edit-task-modal/edit-task-modal.component";

@Component({
  selector: 'hawk-project-edit-ticket-attributes',
  imports: [
    RecordNavigationComponent,
    EditInputComponent,
    NgIf,
    NgForOf,
    FaIconComponent,
    MatButton,
    TooltipModule,
    AddNewTaskModalComponent,
    AddNewPriorityModalComponent,
    AddNewStatusModalComponent,
    EditPriorityModalComponent,
    EditStatusModalComponent,
    EditTaskModalComponent
  ],
  templateUrl: './project-edit-ticket-attributes.component.html',
  standalone: true,
  styleUrls: ['./project-edit-ticket-attributes.component.scss', '../../_shared/styles/common-styles.scss']
})
export class ProjectEditTicketAttributesComponent {

  protected readonly faTrash = faTrash;
  protected readonly faCheckCircle = faCheckCircle;
  protected readonly faHandPaper = faHandPaper;
  protected readonly faBan = faBan;

  @Input() project: FullProjectHash

  editTaskForm: UntypedFormGroup
  editStatusForm: UntypedFormGroup
  editPriorityForm: UntypedFormGroup

  addTaskForm: UntypedFormGroup;
  addStatusForm: UntypedFormGroup;
  addPriorityForm?: UntypedFormGroup;

  openAddTaskForm(): void {
    this.addTaskForm = this.getTaskForm()
  }

  openEditTaskForm(task: FullTaskHash): void {
    this.editTaskForm = this.getTaskForm(task)
  }

  getTaskForm(template?: FullTaskHash): UntypedFormGroup {
    let task_form = new UntypedFormGroup({
      id: new UntypedFormControl({value: '', disabled: true}),
      name: new UntypedFormControl({value: ''}, Validators.required),
      user_ids: new UntypedFormControl({value: []}),
      use_deadline: new UntypedFormControl({value: false}),
      deadline_days: new UntypedFormControl({value: 0}),
      deadline_hours: new UntypedFormControl({value: 0}),
      deadline_minutes: new UntypedFormControl({value: 0}),
      deadline_user_ids: new UntypedFormControl({value: []}),
      project_id: new UntypedFormControl({value: this.project.id}, Validators.required),
      mail: new UntypedFormControl({value: true}),
      sms: new UntypedFormControl({value: false}),
    })

    task_form.controls['id'].setValue(template?.id || null)
    task_form.controls['name'].setValue(template?.name || '')
    task_form.controls['user_ids'].setValue([]) // TODO add template init
    task_form.controls['use_deadline'].setValue(template ? template.use_deadline : false)
    task_form.controls['deadline_days'].setValue(template?.deadline_days || 0)
    task_form.controls['deadline_hours'].setValue(template?.deadline_hours || 0)
    task_form.controls['deadline_minutes'].setValue(template?.deadline_minutes || 0)
    task_form.controls['deadline_user_ids'].setValue([]) // TODO add template init
    task_form.controls['project_id'].setValue(this.project.id)
    task_form.controls['mail'].setValue(template ? template.mail : true)
    task_form.controls['sms'].setValue(template ? template.sms : false)

    task_form.updateValueAndValidity()

    return task_form
  }

  openAddStatusForm(): void {
    this.addStatusForm = this.getStatusForm()
  }

  openEditStatusForm(status: FullStatusHash): void {
    this.editStatusForm = this.getStatusForm(status)
  }

  getStatusForm(template?: FullStatusHash): UntypedFormGroup {
    const status_form = new UntypedFormGroup({
      id: new UntypedFormControl({value: '', disabled: true}),
      name: new UntypedFormControl({value: ''}, Validators.required),
      task_ids: new UntypedFormControl({value: []}),
      closed: new UntypedFormControl({value: false}),
      canceled: new UntypedFormControl({value: false}),
      report_closed: new UntypedFormControl({value: false}),
      color: new UntypedFormControl({value: ''}),
      fixer_forbidden: new UntypedFormControl({value: false}),
      mail_template_id: new UntypedFormControl({value: null}),
      email: new UntypedFormControl({value: ''}),
      project_id: new UntypedFormControl({value: this.project.id}, Validators.required),
    })

    status_form.controls['id'].setValue(template?.id || null)
    status_form.controls['name'].setValue(template?.name || '')
    status_form.controls['task_ids'].setValue([]) // TODO add template init
    status_form.controls['closed'].setValue(template ? template.closed : false)
    status_form.controls['canceled'].setValue(template ? template.canceled : false)
    status_form.controls['report_closed'].setValue(template ? template.report_closed : false)
    status_form.controls['color'].setValue(template?.color || '#ffffff')
    status_form.controls['fixer_forbidden'].setValue(template ? template.fixer_forbidden : false)
    status_form.controls['mail_template_id'].setValue(template?.mail_template?.id || null)
    status_form.controls['email'].setValue(template?.email || '')
    status_form.controls['project_id'].setValue(this.project.id)

    status_form.updateValueAndValidity()

    return status_form
  }

  openAddPriorityForm(): void {
    this.addPriorityForm = this.getPriorityForm()
  }

  openEditPriorityForm(priority: FullPriorityHash): void {
    this.editPriorityForm = this.getPriorityForm(priority)
  }

  getPriorityForm(template?: FullPriorityHash): UntypedFormGroup {
    const priority_form = new UntypedFormGroup({
      id: new UntypedFormControl({value: '', disabled: true}),
      name: new UntypedFormControl({value: ''}, Validators.required),
      color: new UntypedFormControl({value: ''}, Validators.required),
      material_return: new UntypedFormControl({value: false}),
      days: new UntypedFormControl({value: 0}),
      hours: new UntypedFormControl({value: 0}),
      priority_idx: new UntypedFormControl({value: 0}),
      sla_type: new UntypedFormControl({value: 'NO'}),
      telephone_dispatching: new UntypedFormControl({value: false}),
      project_id: new UntypedFormControl({value: this.project.id}, Validators.required),
    })

    priority_form.controls['id'].setValue(template?.id || null)
    priority_form.controls['name'].setValue(template?.name || '')
    priority_form.controls['color'].setValue(template?.color || '#ff0000')
    priority_form.controls['material_return'].setValue(template ? template.material_return : false)
    priority_form.controls['days'].setValue(template?.days || 0)
    priority_form.controls['hours'].setValue(template?.hours || 0)
    priority_form.controls['priority_idx'].setValue(template?.priority_idx || 0)
    priority_form.controls['sla_type'].setValue(template ? template.sla_type : 'NO')
    priority_form.controls['telephone_dispatching'].setValue(template ? template.telephone_dispatching : false)
    priority_form.controls['project_id'].setValue(this.project.id)

    priority_form.updateValueAndValidity()

    return priority_form
  }

}
