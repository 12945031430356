import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormModalComponent } from "../form-modal-component.class";
import { FullProjectTechnician } from "../../../../_models/project-technician.interface";
import { FullProjectHash } from "../../../../_models/project.interface";
import { ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { UserService } from "../../../../_services";
import { Warehouse } from "../../../../_models";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatFormField, MatHint } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOptgroup, MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { NgForOf, NgIf } from "@angular/common";

@Component({
  selector: 'hawk-edit-project-technician-modal',
  imports: [
    MatButton,
    MatCheckbox,
    ReactiveFormsModule,
    MatFormField,
    MatHint,
    MatOption,
    MatSelect,
    MatInput,
    NgIf,
    MatOptgroup,
    NgForOf,
  ],
  templateUrl: './edit-project-technician-modal.component.html',
  standalone: true,
  styleUrls: ['edit-project-technician-modal.component.scss', '../../../_shared/styles/common-styles.scss']
})
export class EditProjectTechnicianModalComponent extends FormModalComponent<FullProjectTechnician, FullProjectHash> implements OnChanges {

  @Input() userID: number | string
  @Input() override formGroup: UntypedFormGroup;
  @Output() override closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() override newDBOutput: EventEmitter<FullProjectHash> = new EventEmitter<FullProjectHash>();

  warehouses: Warehouse[] = []

  constructor(
    private userService: UserService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userID) {
      this.userService.getUsersWarehouses(changes.userID.currentValue).subscribe(
        data => this.warehouses = data,
        err => console.log(err)
      )
    }
  }

  override formGroupToDBInput(): FullProjectTechnician {
    throw new Error('Method not implemented.');
  }

  override getDBCallObservable(object: FullProjectTechnician): Observable<FullProjectHash> {
    throw new Error('Method not implemented.');
  }

}
